// |--------------------------------------------------------------------------
// | Buttons
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .buttons
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Styling
// --------------------------------------------------
.btn {
  font-family: $headings-font-family;
  font-size: 26px;
  border: 5px solid $color-black;

  text-transform: uppercase;
  outline: none;

  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

// --------------------------------------------------
// Variants
// ----

.btn{

  &-small {
    border-width: 3px;
    font-size: 1em;
    text-transform: uppercase;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &.btn-red {
    background-color: $color-red;
    border-color: $color-red;
    color: $color-white;
    &:hover, &:active, &:focus {
      color: $color-red;
      background-color: $color-white;
    }
  }

  &.btn-white {
    background-color: $color-white;
    border-color: $color-white;
    color: $color-blue;
    &:hover, &:active, &:focus {
      color: $color-white;
      background-color: $color-blue;
    }
  }

  &.btn-blue {
    background-color: $color-blue;
    border-color: $color-blue;
    color: $color-white;
    &:hover, &:active, &:focus {
      color: $color-blue;
      background-color: $color-white;
    }
  }

  &.btn-black {
    background-color: $color-black;
    border-color: $color-black;
    color: $color-white;
    &:hover, &:active, &:focus {
      color: $color-black;
      background-color: $color-white;
    }
  }

  &.btn-full-width {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

// Mobile - only
@media #{$breakpoint-mobile-only} {
  .btn {
    white-space: normal;
  }
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {
  .btn {
    padding-left: 50px;
    padding-right: 50px;
  }
}
