// |--------------------------------------------------------------------------
// | 
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | 
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------


// --------------------------------------------------
// Styling
// --------------------------------------------------

.paragraphs-item-webform {

  .panel-body {
    padding: 0;
  }

  fieldset {
    margin-bottom: 0;

    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .form-control {
    border-width: 0;
  }

  .form-actions {
    text-align: right;
    margin-top: 15px;

    .webform-submit.form-submit {
      @extend .btn;
      @extend .btn-blue;
    }
  }

  .field.field-name-field-paragraph-header {
    text-align: left;
  }

  .webform-component-select {
    position: relative;
    &:after {
      position: absolute;
      display: block;
      right: 20px;
      top: 4px;
      content: "";
      width: 30px;
      height: 30px;
      background: $color-white url("../img/arrows-up-down.png") center center no-repeat;
      pointer-events: none;
    }
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

.bg-alternative {

  &.paragraphs-item-webform {

    .field.field-name-field-paragraph-header {
      color: $color-black;
    }

    .form-actions {

      .webform-submit.form-submit {
        @extend .btn-red;
      }
    }
  }
}

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {


}
// Mobile - only
@media #{$breakpoint-mobile-only} {


}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {
  .paragraphs-item-webform {
    .webform-component--row--kolonne-2 {
      div {
        margin-left: 5px;
      }
    }

    .webform-component--row--kolonne-1 {
      div {
        margin-right: 5px;
      }
    }
  }
}

// Tablet - only
@media #{$breakpoint-tablet-only} {


  .social-icons {}
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {




}

// Desktop - only
@media #{$breakpoint-desktop-only} {


}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {


}

// Print
@media print {


}

// Touch
.touchevents {

  // Social icons
  .social-icons {}
}

// No touch
.no-touchevents {

  // Social icons
  .social-icons {}
}

