// |--------------------------------------------------------------------------
// | 
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | 
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------


// --------------------------------------------------
// Styling
// --------------------------------------------------
.paragraphs-item-paragraph-departments{
  padding-bottom: 40px;
  padding-top: 40px;

  .field.field-name-field-paragraph-text{
    p{
      margin-bottom: 0.875em;
    }

    ul{
      font-size: 0.875em;

      list-style: none;
      margin-left: 0;
      padding-left: 0;


      li {
        position: relative;

        p{
          padding-left: 0.875em;
margin-bottom: 0.3em;
        }
        
      }

      li:before {
        font: normal normal normal 0.875em FontAwesome;
        content: "";
        padding-right: 5px;
        color: #01B5D5;
        position: absolute;
        margin-top: 0.35em;
      }
    }
    p + ul{
      margin-top: -0.575em;
    }

  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {


}
// Mobile - only
@media #{$breakpoint-mobile-only} {


}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {

  .paragraphs-item-paragrph-address{
    padding-bottom: 60px;
    padding-top: 60px;
  }

}

// Tablet - only
@media #{$breakpoint-tablet-only} {


  .social-icons {}
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {




}

// Desktop - only
@media #{$breakpoint-desktop-only} {


}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {


}

// Print
@media print {


}

// Touch
.touchevents {

  // Social icons
  .social-icons {}
}

// No touch
.no-touchevents {

  // Social icons
  .social-icons {}
}

