// |--------------------------------------------------------------------------
// | Teaser
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .node-teaser
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Styling
// --------------------------------------------------

// Teaser

.node-os2web-kulturnaut-knactivity {
  position: relative;
  margin-bottom: 30px;
  header h2 {
    font-size: 1.56em;
    color: white;
    margin-bottom: 3px;
  }
  .field-name-field-os2web-kulturnaut-date {
    font-size: 0.94em;
    color: $color-blue;
    font-weight: bold;
  }
  .field-name-field-os2web-kulturnaut-venue,
  .field-name-field-os2web-kulturnaut-sname,
  .field-name-field-os2web-base-field-edi-tags {
    font-size: 0.70em;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
  }
  .field-name-field-os2web-base-field-edi-tags {

  }

  .upper-part {
    padding: 15px;
    position: absolute;
    top: 0;
  }

  .bottom-part {
    border-bottom: 10px solid $color-white;
    border-left: 10px solid $color-white;
    border-right: 10px solid $color-white;
    padding: 15px;
    position: absolute;
    width: 100%;
    bottom: 0;
    @include  gradient-vertical(rgba($color-black,0), rgba($color-black,0.9), 20%, 80%);
  }

  .view-display-id-pane_activities_list_4 & {
    .field-name-field-os2web-kulturnaut-date {
      span.date-display-single{
        font-size: 0.8em;
        font-weight: bold;
        text-transform: lowercase;
        display: block;
      }
      span.date-display-single:first-letter{
        text-transform: uppercase;
      }
    }
    header h2 {
      font-size: 0.9em;
    }
    .field-name-field-os2web-kulturnaut-venue {
      font-size: 1em;
      display: none;
    }
  }
  .one-left & {
    .field-name-field-os2web-kulturnaut-date.field-type-datetime {
      span.date-display-single{
        font-size: 1.32em;
        font-weight: bold;
        text-transform: lowercase;
        display: block;
      }
      span.date-display-single:first-letter{
        text-transform: uppercase;
      }
    }
    .field-name-field-os2web-kulturnaut-venue.field-type-taxonomy-term-reference{
      font-weight: normal;
    }
  }
}
.node-os2web-kulturnaut-knactivity {

  header h2 {
    color: white;
    margin-bottom: 3px;
  }
  .field-name-field-os2web-kulturnaut-date {
    font-size: 0.94em;
    color: $color-blue;
    font-weight: bold;
  }
  .field-name-field-os2web-kulturnaut-venue,
  .field-name-field-os2web-kulturnaut-sname,
  .field-name-field-os2web-base-field-edi-tags {
    font-size: 0.70em;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
  }
  .field-name-field-os2web-base-field-edi-tags {

  }

  .upper-part {
    padding: 15px;
    position: absolute;
    top: 0;
  }

  .bottom-part {
    border-bottom: 10px solid $color-white;
    border-left: 10px solid $color-white;
    border-right: 10px solid $color-white;
    padding: 15px;
    position: absolute;
    width: 100%;
    bottom: 0;
    @include  gradient-vertical(rgba($color-black,0), rgba($color-black,0.9), 20%, 80%);
  }

  .view-display-id-pane_activities_list_4 & {
    .field-name-field-os2web-kulturnaut-date {
      span.date-display-single{
        font-size: 0.8em;
        font-weight: bold;
        text-transform: lowercase;
        display: block;
      }
      span.date-display-single:first-letter{
        text-transform: uppercase;
      }
    }
    header h2 {
      font-size: 0.9em;
    }
    .field-name-field-os2web-kulturnaut-venue {
      font-size: 1em;
      display: none;
    }
  }
  .one-left & {
    .field-name-field-os2web-kulturnaut-date.field-type-datetime {
      span.date-display-single{
        font-size: 1.32em;
        font-weight: bold;
        text-transform: lowercase;
        display: block;
      }
      span.date-display-single:first-letter{
        text-transform: uppercase;
      }
    }
    .field-name-field-os2web-kulturnaut-venue.field-type-taxonomy-term-reference{
      font-weight: normal;
    }
  }
}


// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {

  // Teaser
  .node-teaser {}
}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  // Teaser
  .node-teaser {}
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {

  // Teaser
  .node-teaser {}
}

// Tablet - only
@media #{$breakpoint-tablet-only} {

  // Teaser
  .node-teaser {}
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {

  // Teaser

}

// Desktop - only
@media #{$breakpoint-desktop-only} {

  // Teaser
  .node-teaser {}
}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {

  // Teaser
  .node-teaser {}
}

// Print
@media print {

  // Teaser
  .node-teaser {}
}

// Touch
.touchevents {

  // Teaser
  .node-teaser {}
}

// No touch
.no-touchevents {

  // Teaser
  .node-teaser {}
}

// --------------------------------------------------
// Mixins
// --------------------------------------------------

// Teaser mixin
@mixin node-mixin-teaser() {}
