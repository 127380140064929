// |--------------------------------------------------------------------------
// | Banner
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .banner
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Styling
// --------------------------------------------------

// Banner
.banner {
  @include card-border-radius($card-border-radius);
  margin-bottom: $card-spacer-y;

  .banner-wrapper {
    padding: 30px 50px;

    .banner-headline {
      font-family: $headings-font-family;
      font-weight: $headings-font-weight-bold;
      @extend .heading-h1;
    }

    .banner-text {
      font-size: $font-size-large;
    }

    &.color-1 {
      color: #FFF;
    }

    a {
      @extend .btn;
      @extend .btn-primary;
    }
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  .field-slideshow-pager {
    display: none;
  }
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}

// --------------------------------------------------
// Mixins
// --------------------------------------------------

// Banner mixin
@mixin banner-mixin() {
}
