// |--------------------------------------------------------------------------
// | 
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | 
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------


// --------------------------------------------------
// Styling
// --------------------------------------------------

.paragraphs-item-personale {
  font-size: 1.45em;

  h2 {
    color: $color-black;
  }

  article {
    line-height: 1.2em;
    margin-bottom: 68px;
  }

  .field.field-name-field-paragraph-header {
    margin-bottom: 45px;

    .field-item {
      text-transform: none;
    }
  }

  .page-node.node-type-os2web-base-contentpage & h2 {
    font-size: 1em;
    margin-bottom: 3px;
    text-align: center;

    a {
      color: $color-black;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .field-name-field-personale-billede {
    img {
      width: 172px;
      border: 6px solid $color-gray;
    }
  }

  .field-name-field-personale-jobtitel {
    color: #9b9b9b;
    margin-bottom: 30px;
  }

  .field-name-field-personale-telefon-disp {
    color: $color-red;
  }

  .field-name-field-personale-email {
  }

  .entity-teaser__phone {
    min-height: 26px;
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

.bg-dark {

  &.paragraphs-item-personale {

    h2 {
      color: #fff;
    }

    .field-name-field-personale-billede {

      img {
        border-color: #fff;
      }
    }
  }
}

.bg-darker {

  &.paragraphs-item-personale {

    h2 {
      color: #fff;
    }

    .field-name-field-personale-billede {

      img {
        border-color: #fff;
      }
    }
  }
}

.bg-alternative {

  &.paragraphs-item-personale {

    .field.field-name-field-paragraph-header {
      color: $color-black;
    }

    .field-name-field-personale-jobtitel {
      color: #fff;
    }

    .field-name-field-personale-billede {

      img {
        border-color: $color-black;
      }
    }

    .field-name-field-personale-email {

      a {

        &, &:hover {
          color: $color-black;
        }
      }
    }
  }
}

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {
  .paragraphs-item-personale {
    .row-amount-2 {
      width: 66.666667%;
      margin: 0 auto;
    }
  }
}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}

