// |--------------------------------------------------------------------------
// | Spotbox, Spotbox single, Spotbox mini
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .view-mode-spotbox, .view-mode-os2sub_spotbox_single, .view-mode-os2sub_spotbox_mini
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Styling
// --------------------------------------------------

// Teaser
.node.node-personale.view-mode-spotbox.node--personale--spotbox{
  
  .person{
    display: flex;
    flex-wrap: wrap;
    .field-label{
      width: 150px;
      font-weight: normal;
    }
    .left{
      width: 100%;
    }
    .right{
      width: 100%;
    }
    .field.field-name-field-personale-jobtitel{
      margin-bottom: 20px;
      color: #999;
      &:before{
        content: '/\00a0';
        float: left;
      }
    }
    .field.field-name-field-personale-telefon-disp, .field.field-name-field-personale-telefon{
      color: $brand-secondary;
    }
    .field.field-name-field-personale-email{
      color: $brand-primary;
    }
  }
}


// --------------------------------------------------
// Variants
// --------------------------------------------------


// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {




}

// Mobile - only
@media #{$breakpoint-mobile-only} {


}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {
  .node.node-personale.view-mode-spotbox.node--personale--spotbox{
    .person{
      .left{
        width: 40%;
      }
      .right{
        width: 60%;
      }
      .field.field-name-field-personale-jobtitel{
        margin-bottom: 0px;
      }
    }
  }
}

// Tablet - only
@media #{$breakpoint-tablet-only} {


}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {


}
// Desktop - only
@media #{$breakpoint-desktop-only} {



}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {


}

// Print
@media print {


}

// Touch
.touchevents {

  // Page layout
  .content-inner {}
}

// No touch
.no-touchevents {


}
// --------------------------------------------------
// Mixins
// --------------------------------------------------

// Page layout mixin
@mixin content-mixin-inner() {}
