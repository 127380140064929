// |--------------------------------------------------------------------------
// | Modal popups
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .header
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Styling
// --------------------------------------------------

#modalContent {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  overflow-y: scroll;
  bottom: 0;
  right: 0;
  -webkit-overflow-scrolling: touch;
}

div.ctools-modal-content {
  margin: 10% auto;
  background: transparent;
  border: 0;
  width: 80%;

  h1 {
    font-size: 3.5em;
    color: $brand-primary;
    margin-bottom: 20px;

    a {
      cursor: text;
      color: $brand-primary;
      text-decoration: none;
      outline: none;
    }
  }

  .modal-content {
    border-radius: 0;
    padding: 20px 50px 50px;
    box-shadow: none;
    border: 0;

    .field-name-body {
      padding-top: 10px;
      font-weight: bold;
    }
  }

  .popups-close {
    display: block;
    height: 70px;

    .close {
      height: 70px;
      width: 70px;
      display: block;
      background: $color-blue;
      padding: 10px 0;
      text-align: center;
      font-size: 3em;
      opacity: 1;
    }
  }
}
// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {

}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  div.ctools-modal-content {
    margin: 10px auto;
    width: 90%;

    h1 {
      font-size: 32px !important;
    }

    .modal-content {
      padding: 10px 20px;
    }

    .node.node-os2web-contact-contact.node--os2web_contact_contact--full {
      font-size: 18px;
    }
  }
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {

}

// Tablet - only
@media #{$breakpoint-tablet-only} {

}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {
  //trick to mane menu clickable on the front page because of background slideshow

  div.ctools-modal-content {
    width: 970px;
  }

}

// Desktop - only
@media #{$breakpoint-desktop-only} {

}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {

}

// Print
@media print {

}

// Touch
.touchevents {

}

// No touch
.no-touchevents {

}

// --------------------------------------------------
// Mixins
// --------------------------------------------------

// Header mixin
@mixin header-mixin() {
}
