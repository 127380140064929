// |--------------------------------------------------------------------------
// | Social icons
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .social-icons
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

$social-icons-height: 40px;
$social-icons-width: 40px;
$social-icons-font-size: 22px;

// --------------------------------------------------
// Styling
// --------------------------------------------------

// Social icons
.social-icons-list {
  @extend .list-inline;
  padding: 0;
  margin-bottom: 5px;
  margin-top: 10px;
  text-align: center;

  li {
    text-align: center;
  }

  .social-icon {
    display: block;
    font-family: FontAwesome;
    border-radius: 100%;
    border: 0;
    height: $social-icons-height;
    width: $social-icons-width;
    line-height: $social-icons-height;
    vertical-align: middle;
    font-size: $social-icons-font-size;

    &:hover {
      text-decoration: none;
    }

    &,
    &:hover,
    &:focus,
    &:active {
      color: #fff;
      text-decoration: none;
    }

    // Facebook
    &.social-icon-facebook {
      background-color: $social-color-facebook;

      &:before {
        content: $fa-var-facebook;
      }
    }

    // Instagram
    &.social-icon-instagram {
      background-color: $social-color-instagram;

      &:before {
        content: $fa-var-instagram;
      }
    }

    // Twitter
    &.social-icon-twitter {
      background-color: $social-color-twitter;

      &:before {
        content: $fa-var-twitter;
      }
    }

    // Pinterest
    &.social-icon-pinterest {
      background-color: $social-color-pinterest;

      &:before {
        content: $fa-var-pinterest-p;
      }
    }

    // Google Plus
    &.social-icon-google-plus {
      background-color: $social-color-google-plus;

      &:before {
        content: $fa-var-google-plus;
      }
    }

    // LinkedIn
    &.social-icon-linkedin {
      background-color: $social-color-linkedin;

      &:before {
        content: $fa-var-linkedin;
      }
    }

    // Vimeo
    &.social-icon-vimeo {
      background-color: $social-color-vimeo;

      &:before {
        content: $fa-var-vimeo;
      }
    }

    // Youtube
    &.social-icon-youtube {
      background-color: $social-color-youtube;

      &:before {
        content: $fa-var-youtube;
      }
    }
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {

  // Social icons
  .social-icons {}
}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  // Social icons
  .social-icons {}
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {

  // Social icons
  .social-icons {}
}

// Tablet - only
@media #{$breakpoint-tablet-only} {

  // Social icons
  .social-icons {}
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {

  // Social icons
  .social-icons {}
}

// Desktop - only
@media #{$breakpoint-desktop-only} {

  // Social icons
  .social-icons {}
}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {

  // Social icons
  .social-icons {}
}

// Print
@media print {

  // Social icons
  .social-icons {}
}

// Touch
.touchevents {

  // Social icons
  .social-icons {}
}

// No touch
.no-touchevents {

  // Social icons
  .social-icons {}
}

// --------------------------------------------------
// Mixins
// --------------------------------------------------

// Social icons mixin
@mixin social-mixin-icons() {}
