// |--------------------------------------------------------------------------
// |
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// |
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------


// --------------------------------------------------
// Styling
// --------------------------------------------------

.paragraphs-item-afsnit-m-link{
  padding-bottom: floor(1.5*$grid-gutter-width);
  padding-top: floor(1.5*$grid-gutter-width);
  font-family: $headings-font-family;

  .field.field-name-field-paragraph-header{
    font-size: 3.125em;
    margin-bottom: 0;
  }

  .field.field-name-field-knap-link{

    a{
      @extend .btn;
      @extend .btn-blue;

      margin-top: 3px;
    }
  }

  .aligner-left{
    text-align: center;
  }

  .aligner-right{
    text-align: center;
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

.bg-dark {

  &.paragraphs-item-afsnit-m-link{

    .field.field-name-field-knap-link {

      a {
        @extend .btn-red;
      }
    }
  }
}

.bg-darker {

  &.paragraphs-item-afsnit-m-link{

    .field.field-name-field-knap-link {

      a {
        @extend .btn-red;
      }
    }
  }
}

.bg-alternative {

  &.paragraphs-item-afsnit-m-link{

    .field.field-name-field-knap-link {

      a {
        @extend .btn-black;
      }
    }
  }
}

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  .paragraphs-item-afsnit-m-link {

    .paragraph-position--right {

      .field.field-name-field-paragraph-text {
        margin-bottom: 0;
      }

      .field-name-field-knap-link {
        margin-bottom: $grid-gutter-width;
      }
    }
  }
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {

  .paragraphs-item-afsnit-m-link{
    padding-bottom: 60px;
    padding-top: 60px;

    .aligner-left{
      text-align: left;
    }

    .aligner-right{
      text-align: right;
    }
  }
}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
