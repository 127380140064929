.responsive-header {
  padding: 0 floor( ( $grid-gutter-width / 2 ) );
  margin-bottom: 10px;
  background-image: linear-gradient(rgba(0, 0, 0, .2) 20%, transparent 70%);

  .logo {

    img {
      max-height: 70px;
      //margin-left: 0 - floor( ( $grid-gutter-width / 2 ) );
    }
  }

  .flexy-spacer {
    flex-grow: 1;
  }

  .sidr__toggle {
    padding: 20px 0; // Fat finger proof

    background-color: transparent;
    border-color: transparent;

    .icon {
      font-size: 24px;

      color: #fff;
    }
  }
}

.responsive-header__row--first {
  display: flex;

  align-content: center;
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  .search-form,
  .views-exposed-form {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    padding: 0 15px;

    .form-control {
      border: 0;

      height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
      padding: $padding-base-vertical $padding-base-horizontal;
      font-size: $font-size-large !important;
      line-height: $line-height-base;
      color: $text-color !important;
      background-color: #fff;
      border-color: #fff;

      @include placeholder(#ff4200 !important);
    }
  }

  #views-exposed-form-os2sub-kulturnaut-multi-search-pane-activities-multi-search .views-exposed-widgets .views-exposed-widget.views-submit-button {
    top: 0;

    button {
      border: 1px solid #fff;
      background-color: #fff;

      padding: $padding-base-vertical $padding-base-horizontal !important;
      font-size: 0;
      line-height: $line-height-base;

      &:after {
        font-size: $font-size-base;
        color: #ff4200;
      }
    }
  }
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {
}

// Tablet - only
@media #{$breakpoint-tablet-only} {
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {

}

// Desktop - only
@media #{$breakpoint-desktop-only} {
}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {
}

// Print
@media print {
}

// Touch
.touchevents {
}

// No touch
.no-touchevents {
}

// --------------------------------------------------
// Mixins
// --------------------------------------------------
