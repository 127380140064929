// |--------------------------------------------------------------------------
// | 
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | 
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------


// --------------------------------------------------
// Styling
// --------------------------------------------------
.paragraphs-item-personale{
  font-family: $headings-font-family;
  text-align: center;

  .field-items{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .field-item{
      position: relative;

      .field.field-name-field-personale-billede{
        img{
          border: 6px solid #e6e9ec !important;
        }
      }
      .field.field-name-field-personale-jobtitel{
        color: #999;
        font-size: 1.375em;
      }
      .field.field-name-field-personale-telefon,
      .field.field-name-field-personale-telefon-disp{
        font-size: 1.375em;
        color: $brand-secondary;
        a{
          color: $brand-secondary;
        }
      }
      .field.field-name-field-personale-email{
        font-size: 1.375em;
      }
    }
    h2{
      a{
        color: $gray-darker;
        font-size: 1.375em;
      }
    }
  }
  article{
    margin-bottom: 60px;
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {


}
// Mobile - only
@media #{$breakpoint-mobile-only} {


}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {

}

// Tablet - only
@media #{$breakpoint-tablet-only} {


  .social-icons {}
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {




}

// Desktop - only
@media #{$breakpoint-desktop-only} {


}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {


}

// Print
@media print {


}

// Touch
.touchevents {

  // Social icons
  .social-icons {}
}

// No touch
.no-touchevents {

  // Social icons
  .social-icons {}
}

