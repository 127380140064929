// |--------------------------------------------------------------------------
// | os2web-kulturnaut-events-lists
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .view-os2web-kulturnaut-events-lists
// |


// --------------------------------------------------
// Variables
// --------------------------------------------------

//size of header in desktop orientation and up
// --------------------------------------------------
// Styling
// --------------------------------------------------

.pane-os2web-kulturnaut-events-lists-pane-activities-list-4-mini{
  padding-bottom: 85px;
  padding-top: 75px;
  & .show-all{
    text-align: right;
    margin-top: 45px;
  }

}
// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {

  // Banner
  .banner {
  }
}

// Mobile - only
@media #{$breakpoint-mobile-only} {

}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {

  // Banner
  .banner {
  }
}

// Tablet - only
@media #{$breakpoint-tablet-only} {

}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {


}
// Desktop - only
@media #{$breakpoint-desktop-only} {

  // Banner
  .banner {
  }
}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {

  // Banner
  .banner {
  }
}

// Print
@media print {

  // Banner
  .banner {
  }
}

// Touch
.touchevents {

  // Banner
  .banner {
  }
}

// No touch
.no-touchevents {

  // Banner
  .banner {
  }
}