// |--------------------------------------------------------------------------
// | Slinky menu
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .slinky-menu
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Mixins
// --------------------------------------------------

@mixin slinky-menu-mixin() {}

// --------------------------------------------------
// Styling
// --------------------------------------------------

.slinky-menu {
  color: #fff;

  .menu-name--main-menu {

    + .menu-name--user-menu {
      margin-top: -1px;

      border-top: 1px solid #fff;
    }
  }

  a {
    text-transform: uppercase;
  }

  li {
    border-bottom: 1px solid #2a2f36;
  }

  .back {
    top: 19px;
  }

  .title {
    font-weight: bold;
    text-decoration: underline;
  }

  .next {

    &:after {
      @extend .fa;
      font-size: 18px;
      content: $fa-var-angle-right;

      color: rgba(255, 255, 255, .5);
    }
  }

  .nolink,
  .separator {
    display: none;
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
