// |--------------------------------------------------------------------------
// | Social icon colors
// |--------------------------------------------------------------------------
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

$social-color-facebook:    #3b5998;
$social-color-twitter:     #55acee;
$social-color-instagram:   #3f729b;
$social-color-linkedin:    #0976b4;
$social-color-pinterest:   #cc2127;
$social-color-vimeo: 	    #1ab7ea;
$social-color-youtube:     #e52d27;
$social-color-google-plus: #d64431;
