// |--------------------------------------------------------------------------
// | Table
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .stackable
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------



// --------------------------------------------------
// Variants
// --------------------------------------------------


.stacktable {
  width: 100%;

  &.large-only {
    display: none;
  }

  &.small-only {
    display: table;
  }
}

.st-head-row {
  padding-top: 1em;
}
.st-head-row.st-head-row-main {
  font-size: 1.5em; padding-top: 0;
}
.st-key {
  width: 49%; text-align: right; padding-right: 1%;
}
.st-val {
  width: 49%; padding-left: 1%;
}

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {
}

// Mobile - only
@media #{$breakpoint-mobile-only} {
  .stacktable {

    tbody {

      tr:first-child {
        display: none;
      }
    }
  }
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {
  .stacktable.large-only {
    display: table;
  }
  .stacktable.small-only {
    display: none;
  }
}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}

// --------------------------------------------------
// Mixins
// --------------------------------------------------

