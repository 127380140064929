// |--------------------------------------------------------------------------
// | 
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | 
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------// --------------------------------------------------
// Styling
// --------------------------------------------------

.paragraphs-item-header-text-links-image {
  padding-bottom: $grid-gutter-width;
  padding-top: $grid-gutter-width;

  .field-name-field-paragraph-header {
    color: $color-red;
  }

  .row {

    // Fixes Safari issue (https://stackoverflow.com/questions/34250282/flexbox-safari-bug-flex-wrap)
    &:after,
    &:before {
      content: normal; // IE doesn't support `initial`
    }

    .field.field-name-field-knap-link {

      a {
        @extend .btn;
        @extend .btn-red;

        font-size: 1.875em !important;
        width: auto !important;
        height: auto !important;
        line-height: 0.9em !important;
        font-family: $headings-font-family !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        font-weight: normal !important;

        margin-bottom: 5px;
      }

      .field-item {
        display: inline-block;

        margin-right: 5px;
      }
    }

    .field.field-name-field-image.field-type-image {

      img {
        border-style: solid;
        border-width: 6px;
        border-color: $color-black;
      }
    }
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

.bg-dark {

  &.paragraphs-item-header-text-links-image {

    .field.field-name-field-image.field-type-image {

      img {
        border-color: #fff;
      }
    }
  }
}

.bg-darker {

  &.paragraphs-item-header-text-links-image {

    .field.field-name-field-image.field-type-image {

      img {
        border-color: #fff;
      }
    }
  }
}

.bg-alternative {

  &.paragraphs-item-header-text-links-image {

    .field-name-field-paragraph-header {
      color: #fff;
    }
  }
}

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {

  .paragraphs-item-header-text-links-image {
    padding-bottom: 60px;
    padding-top: 60px;

    .row {

      .field.field-name-field-knap-link {

        .field-item {
          margin-left: 0 !important;
          margin-right: 15px !important;
        }
      }
    }
  }
}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
