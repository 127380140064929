// |--------------------------------------------------------------------------
// | Node full
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .node
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Styling
// --------------------------------------------------

// Node
.node {
  font-size: 1.06em;
  line-height: 1.58em;

  &.view-mode-full {
    img,
    .media_embed {
      max-width: 100%;
    }
  }
}

.page-node {

  &.node-type-os2web-base-contentpage {
    h1 {
      font-size: 3.8em;
      margin-bottom: 20px;
    }
  }

  .node--os2web_base_contentpage--full {

    h2 {
      font-size: 2em;
    }

    table {
      caption {
        font-size: 2em;
        font-family: $font-rbn021a;
        color: $color-black;
        margin-top: 20px;
      }

      tbody > tr > td,
      thead > tr > th {
        border: 0;
      }

      th {
        font-size: 1.1em;
        padding: 15px 8px;
      }

      tbody > tr {
        > td {
          padding: 5px 15px;
          border-right: 2px solid $color-white;

          &:last-child {
            border: 0;
          }
        }
      }

      &.table-striped > tbody > tr:nth-of-type(odd) {
        background: $color-gray;
      }
    }
  }

  .pane-node-field-os2web-kulturnaut-slidesho,
  .pane-node-field-os2web-base-field-image,
  .pane-node-field-os2web-base-field-lead-img {
    margin-bottom: $grid-gutter-width;

    .os2-box {
      position: relative;

      img {
        border: solid 6px $color-black;
      }

      .field-slideshow-controls {
        position: absolute;
        top: 0;
        z-index: 99;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;

        a {
          display: block;
          width: 64px;
          height: 64px;
          background-color: $brand-primary;
          font-size: 0;
          position: relative;
          &.prev {
            margin-left: -32px;
            &:before {
              border: 20px solid transparent;
              border-right: 20px solid #fff;
              content: '';
              position: absolute;
              margin-top: 12px;
              margin-bottom: 12px;
            }
          }
          &.next {
            margin-right: -32px;
            &:after {
              border: 20px solid transparent;
              border-left: 20px solid #fff;
              content: '';
              position: absolute;
              left: 24px;
              margin-top: 12px;
              margin-bottom: 12px;
            }
          }
        }
      }
      .field-slideshow-pager {
        position: absolute;
        bottom: 0;
        z-index: 99;
        right: 0;
        margin-right: 24px;
        margin-bottom: 30px;
        display: flex;
        a {
          border-style: solid;
          border-width: 5px;
          border-color: rgba(255, 255, 255, 0.5);
          width: 24px;
          height: 24px;
          font-size: 0;
          margin-left: 6px;
          margin-right: 6px;
          display: block;
        }
        a.activeSlide,
        a.active {
          border-color: rgba(255, 255, 255, 0.9);
        }
      }
    }
  }

  .content-sidebar {
    border-left: 2px solid #ddd;
    padding-bottom: ($grid-gutter-width * 2);
    .panel-pane {
      padding-left: ($grid-gutter-width/2);
    }
    h2 {
      color: $brand-secondary;
    }
  }
  .pane-node-content h2.pane-title,
  h1.pane-title {
    padding-bottom: 0;
    color: $brand-primary;
  }
  .field-name-field-os2web-base-field-summary {
    @extend .lead;
    font-family: $headings-font-family;
  }
  .os2-section.os2-section-light {
    .pane-fic-kommende-arrangementer-panel-pane-1 {
      margin-top: $grid-gutter-width;
      padding-top: $grid-gutter-width;
      border-top: 2px solid #ddd;
    }
  }
  .os2-section.full-width {
    .paragraphs-container {
      @include container-fixed;

      @media (min-width: $screen-sm-min) {
        width: $container-sm;
      }
      @media (min-width: $screen-md-min) {
        width: $container-md;
      }
      @media (min-width: $screen-lg-min) {
        width: $container-lg;
      }

      padding-top: $grid-gutter-width;
      padding-bottom: $grid-gutter-width;
      .view-content {
        @include make-row();

        .views-row {
          @include make-xs-column(6);
          @include make-sm-column(4);
        }
      }
    }
  }
  .panel-pane.pane-entity-field.pane-node-field-os2web-kulturnaut-saddress {
    iframe {
      border: solid 6px $color-black;
      max-width: 100%;
      margin: 20px 0;
    }
  }

  // Fix bottom content offset. See: .bg-undefined
  &.node-type-os2web-base-contentpage .os2-sectiont,
  &.node-type-os2web-base-news .pane-node-content {
    margin-bottom: 30px;
  }

  &.node-type-os2web-base-news {

    .os2-section.content-primary {
      padding-bottom: 30px;
    }
  }
}

.node.node-os2web-contact-contact.node--os2web_contact_contact--full {
  font-size: 1.875em;
  font-family: $headings-font-family;
  color: $gray-darker;
  .all-contacts {
    margin-top: 55px;
    text-align: right;

    a.btn {
      margin-left: $grid-gutter-width;
    }
  }
  .field.field-name-field-paragraph-personale {
    & > .field-items {
      & > .field-item {
        border-top: 2px solid $color-light-gray;
        padding-top: 30px;
        padding-bottom: 30px;
        &:first-child {
          margin-top: 30px;
        }
        &:last-child {
          border-bottom: 2px solid $color-light-gray;
        }
      }
    }
  }
  h1 {
    font-size: 2em;
    color: $brand-primary;
  }
  .all-contacts {
    .field,
    .field-items,
    .field-item {
      display: inline;
    }
  }
}

.node-type-os2web-kulturnaut-knactivity {

  .pane-title {
    padding-bottom: 0;
    margin-bottom: 0;

    font-size: 2.500em;
  }

  .content-sidebar {

    .field-item {
      font-weight: bold;
    }
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

.os2-section.os2-section-light {
  .pane-node-field-os2web-kulturnaut-slidesho {
    img {
      border: solid 6px $color-black;
    }
  }
}

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {

}

// Mobile - only
@media #{$breakpoint-mobile-only} {
  .node {
    &.view-mode-full {
      img,
      .media_embed {
        height: auto !important;
      }
    }
  }

  .page-node {
    .pane-node-field-os2web-kulturnaut-slidesho .os2-box,
    .os2-sectiont .field-name-field-os2web-base-field-image {
      .field-slideshow-pager {
        display: none !important;
      }

      .field-slideshow-controls {

        a {
          width: 34px;
          height: 34px;

          &.prev {
            margin-left: -6px;

            &:before {
              margin-top: 7px;
              margin-bottom: 0;

              border: 10px solid transparent;
              border-right: 10px solid #fff;
            }
          }

          &.next {
            margin-right: -6px;

            &:after {
              margin-top: 7px;
              margin-bottom: 0;

              left: 14px;

              border: 10px solid transparent;
              border-left: 10px solid #fff;
            }
          }
        }
      }
    }
  }
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {
  .node {
    &.view-mode-full {
      img,
      .media_embed {
        height: auto !important;
      }
    }
  }
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {

  // Banner
  .banner {
  }
}

// Desktop - only
@media #{$breakpoint-desktop-only} {

  // Banner
  .banner {
  }
}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {

  // Banner
  .banner {
  }
}

// Print
@media print {

  // Banner
  .banner {
  }
}

// Touch
.touchevents {

  // Banner
  .banner {
  }
}

// No touch
.no-touchevents {

  // Banner
  .banner {
  }
}
