// |--------------------------------------------------------------------------
// | Div'ed table
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .dived-table
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Styling
// --------------------------------------------------

// Div'ed table
.dived-table {
  display: table;
  width: 100%;
  border-collapse: collapse;

  .dived-table-row {
    display: table-row;
  }

  .dived-table-head {
    font-weight: bold;
  }

  .dived-table-head,
  .dived-table-cell {
    display: table-cell !important;
    vertical-align: top;
  }

  .div-vertical-align-middle {
    vertical-align: middle;
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {

  // Div'ed table
  .dived-table {}
}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  // Div'ed table
  .dived-table {}
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {

  // Div'ed table
  .dived-table {}
}

// Tablet - only
@media #{$breakpoint-tablet-only} {

  // Div'ed table
  .dived-table {}
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {

  // Div'ed table
  .dived-table {}
}

// Desktop - only
@media #{$breakpoint-desktop-only} {

  // Div'ed table
  .dived-table {}
}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {

  // Div'ed table
  .dived-table {}
}

// Print
@media print {

  // Div'ed table
  .dived-table {}
}

// Touch
.touchevents {

  // Div'ed table
  .dived-table {}
}

// No touch
.no-touchevents {

  // Div'ed table
  .dived-table {}
}

// --------------------------------------------------
// Mixins
// --------------------------------------------------

// Div'ed table mixin
@mixin dived-table-mixin() {}
