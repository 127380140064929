// |--------------------------------------------------------------------------
// | 
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | 
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------


// --------------------------------------------------
// Styling
// --------------------------------------------------
.paragraphs-item-kolonner {
  .field.field-name-field-paragraph-text {
    margin: 1em 0;
  }

  .field-name-field-knap-link a {
    @extend .btn;
    @extend .btn-blue;
  }
}


// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {


}
// Mobile - only
@media #{$breakpoint-mobile-only} {


}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {
}

// Tablet - only
@media #{$breakpoint-tablet-only} {


  .social-icons {}
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {




}

// Desktop - only
@media #{$breakpoint-desktop-only} {


}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {


}

// Print
@media print {


}

// Touch
.touchevents {

  // Social icons
  .social-icons {}
}

// No touch
.no-touchevents {

  // Social icons
  .social-icons {}
}

