@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900|Roboto+Slab:300,400,700|Open+Sans:400,600,700&subset=latin-ext');

@font-face {
  font-family: 'RBNo21a-Medium';
  src: url('../fonts/34D611_0_0.eot');
  src: url('../fonts/34D611_0_0.eot?#iefix') format('embedded-opentype'), url('../fonts/34D611_0_0.woff2') format('woff2'), url('../fonts/34D611_0_0.woff') format('woff'), url('../fonts/34D611_0_0.ttf') format('truetype');
}

$font-roboto-slab: 'Roboto Slab', serif;
$font-lato: 'Lato', sans-serif;
$font-open-sans: 'Open Sans', sans-serif;
$font-rbn021a: 'RBNo21a-Medium', sans-serif;

