// I used alot over !importants in this file. Dont ever use !importants. By @hjemmesidekongen
.field-slideshow-wrapper {

  .field-slideshow-controls {
    position: absolute;

    top: auto !important;
    bottom: 0;

    align-items: flex-end !important;
    padding-bottom: 6px;

    .prev {
      margin-left: 6px !important;
    }
    .next {
      margin-right: 6px !important;
    }
  }

  .field-slideshow-pager {
    display: none !important;
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  .field-slideshow {
    width: 100% !important;
  }
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {

  .not-front.page-node {

    .page-header-wrapper {

      .backstretch {

        &:before {
          position: absolute;

          display: block;
          content: "";

          height: 30px;

          right: 0;
          left: 0;
          bottom: 0;

          background-color: #fff;
        }
      }
    }
  }

  .page-taxonomy-term {

    .page-header-wrapper {

      &:before {
        display: none;
      }
    }
  }
}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
