// |--------------------------------------------------------------------------
// | fic-header-banners
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .view-fic-header-banners
// |
// --------------------------------------------------
// Variables
// --------------------------------------------------

// Responsive
$xs-pager-spacing: 5px;
$sm-pager-spacing: 10px;
$md-pager-spacing: 30px;
$lg-pager-spacing: 30px;

// --------------------------------------------------
// Styling
// --------------------------------------------------

.term-fic-header {
  @include container-fixed();
  font-family: $font-rbn021a;
  position: relative;
  // Hide empty FIC header.
  display: none;

  // Show FIC header only for pages with backstreched image.
  .backstretched & {
    display: block;
  }

  .views-slideshow-cycle-main-frame-row {
    width: 100% !important;
    top: auto !important;
    bottom: auto !important;
    height: auto !important;
  }

  .views_slideshow_cycle_main.views_slideshow_main.viewsSlideshowCycle-processed{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .views-slideshow-cycle-main-frame.views_slideshow_cycle_teaser_section{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: auto !important;
  }
  .views-row {
    height: inherit;
  }

  .slide-content-wrapper {
    text-align: center;

    .field-name-description-field,
    .field-name-field-os2web-base-field-summary {
      color: $color-white;
      font-size: 4em;
      line-height: 1.15em;
      text-shadow: 1px 1px rgba(0, 0, 0, .75);
    }

    .field-name-field-os2web-base-field-ext-link {
      .field-item {
        display: inline-block;
        margin: 20px 10px;

        a {
          padding: 10px;
          display: block;
          font-size: 1em;
          font-family: sans-serif;
          font-weight: bold;
          text-transform: uppercase;
          color: $color-white;
          background-color: $color-blue;
          min-width: 185px;

          &:hover, &:focus {
            text-decoration: none;
          }
        }
      }
    }
  }

  .views-slideshow-controls-bottom,
  .field-name-field-os2web-base-field-related,
  .cycle-pager {

    > div {
      overflow: hidden;
    }

    a {
      font-size: 1.7em;
      line-height: 1em;
      text-transform: uppercase;
      display: block;
      background: $color-white;
      color: $color-black;
      position: relative;
      text-decoration: none;
      border: 5px solid $color-white;
      overflow: hidden;
      height: 80px;

      span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 20px 0 10px;
        display: block;
      }

      &:hover {
        background: $color-red;
        color: $color-white;
      }

      &::after {
        content: '›';
        display: block;
        height: 31px;
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        font-size: 1.4em;
        font-family: "sans-serif";
      }
    }

    .views-slideshow-pager-field-item,
    .field-item {
      //margin-right: $desktop-margin-default;

      &.views-row-first,
      &:first-child {
        display: none;
      }
    }
  }

  .views-slideshow-controls-bottom {
    font-size: 0;

    .views-slideshow-pager-field-item {
      font-size: 14px;

      span {
        width: 130px;
      }
    }
  }

  .slide-bottom {
    text-transform: uppercase;

    .blue-block {
      background-color: $color-blue;
      min-height: 60px;
      width: 100%;

      a {
        display: block;
      }

      .text {
        color: $color-white;
        font-size: 2em;
        padding: 10px 10px 10px 17px;
        display: inline-block;
      }

      .read-more {
        color: $color-black;
        text-decoration: none;
        float: right;
        display: block;
        padding: 20px;
        font-size: 1em;
        font-family: sans-serif;
        font-weight: bold;
      }
    }

    .contact.modal-link {
      display: block;
      width: 100%;
      height: 100%;
      background-color: $color-red;
      color: $color-white;
      padding: 10px 0;
      text-align: center;
      font-size: 2em;

      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }
}

.scroll-down {

  &-link {
    text-align: center;
    display: none;
    padding: 50px 0;

    .page-taxonomy-term & {
      display: block;
    }

    span {
      display: inline-block;
      height: 60px;
      width: 60px;
      background-image: url('../img/lookdown.png');
      background-repeat: no-repeat;
      transition: opacity 0.3s;
    }
    span:hover {
      opacity: 0.5;
    }
  }
}

.not-front.page-taxonomy {
  .view-mode-fic_header {
    > .cycle-slideshow-wrapper {
      display: flex;
      align-items: center;
      width: 100%;

      .cycle-slideshow {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100% !important;
        height: auto !important;

        .cycle-slide {
          background-color: transparent !important;
          width: 100% !important;
          top: auto !important;
          bottom: auto !important;
          height: auto !important;
        }

      }
    }
  }
}

.node-type-os2web-base-contentpage {

  .backstretched & .page-header-wrapper {
    margin-bottom: 30px;
  }

  .slide-content-wrapper {
    margin: 30px 0 110px;
  }
}


.widget_pager_bottom,
.cycle-pager {
  @include make-row($xs-pager-spacing);

  min-height: 80px + $xs-pager-spacing;

  > .views-slideshow-pager-field-item,
  > .field-item {
    position: relative;

    // If we are not overriding with !important, the include make-x-column
    // is overriding the custom pager spacing.
    padding-left:  ($xs-pager-spacing / 2) !important;
    padding-right: ($xs-pager-spacing / 2) !important;

    a {
      display: block;

      width: auto; // Reset

      margin-bottom: $xs-pager-spacing;
    }
  }

  // 2 items
  .num-slides-3 &,
  &.num-items-3 {

    > .views-slideshow-pager-field-item,
    > .field-item {
      @include make-xs-column(6);
      @include make-sm-column(6);
      @include make-md-column(6);
      @include make-lg-column(6);
    }
  }

  // 3 items
  .num-slides-4 &,
  &.num-items-4 {

    > .views-slideshow-pager-field-item,
    > .field-item {
      @include make-xs-column(6);
      @include make-sm-column(4);
      @include make-md-column(4);
      @include make-lg-column(4);
    }
  }

  // 4 items
  .num-slides-5 &,
  &.num-items-5 {

    > .views-slideshow-pager-field-item,
    > .field-item {
      @include make-xs-column(6);
      @include make-sm-column(3);
      @include make-md-column(3);
      @include make-lg-column(3);
    }
  }

  // 5 items
  .num-slides-6 &,
  &.num-items-6 {

    > .views-slideshow-pager-field-item,
    > .field-item {
      @include make-xs-column(6);
      @include make-sm-column(3);
      @include make-md-column(3);
      @include make-lg-column(3);
    }
  }

  // 6 items
  .num-slides-7 &,
  &.num-items-7 {

    > .views-slideshow-pager-field-item,
    > .field-item {
      @include make-xs-column(6);
      @include make-sm-column(4);
      @include make-md-column(2);
      @include make-lg-column(2);
    }
  }
}

.slide-bottom {
  @include make-row($xs-pager-spacing);

  [class*=col] {

    // If we are not overriding with !important, the include make-x-column
    // is overriding the custom pager spacing.
    padding-left:  ($xs-pager-spacing / 2) !important;
    padding-right: ($xs-pager-spacing / 2) !important;
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  .not-front {

    .header-container {
      padding-bottom: 0;

      min-height: 115px;
    }

    .backstretch {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      height: auto !important;
      width: auto !important;
    }

    .scroll-down-wrapper {
      display: none;
    }

    .term-fic-header {

      .slide-content-wrapper {
        margin: 0 !important;

        .field-name-description-field,
        .field-name-field-os2web-base-field-summary {
          display: flex;

          align-items: center;

          min-height: 160px;
          margin-bottom: 0;
        }
      }
    }
  }

  .term-fic-header {

    .slide-content-wrapper {

      .field-name-description-field,
      .field-name-field-os2web-base-field-summary {
        font-size: 2.5em;
        line-height: 1em;
      }
    }

    .cycle-slideshow-wrapper,
    .views_slideshow_main {
      height: 160px !important;
    }

    .views_slideshow_cycle_slide {
      width: 100% !important;
    }

    .slide-bottom {
      .blue-block {
        min-height: auto;
      }

      .text {
        font-size: 1.7em;
        line-height: 1em;
      }
    }

    .contact.modal-link {
      font-size: 1.7em;
      line-height: 1em;
    }
  }

  .scroll-down-wrapper {
    height: 90px;

    margin: 0;

    .scroll-down-link {
      display: none;
    }
  }

  .widget_pager_bottom,
  .cycle-pager {
    @include make-row($xs-pager-spacing);

    > .views-slideshow-pager-field-item,
    > .field-item {

      // If we are not overriding with !important, the include make-x-column
      // is overriding the custom pager spacing.
      padding-left:  floor(($xs-pager-spacing / 2)) !important;
      padding-right: ceil(($xs-pager-spacing / 2)) !important;
    }
  }

  .slide-bottom {
    @include make-row($xs-pager-spacing);

    [class*=col] {

      // If we are not overriding with !important, the include make-x-column
      // is overriding the custom pager spacing.
      padding-left:  floor(($xs-pager-spacing / 2)) !important;
      padding-right: ceil(($xs-pager-spacing / 2)) !important;
    }
  }
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {

  .widget_pager_bottom,
  .cycle-pager {
    @include make-row($sm-pager-spacing);

    min-height: 80px + $sm-pager-spacing;

    > .views-slideshow-pager-field-item,
    > .field-item {
      padding-left:  floor(($sm-pager-spacing / 2)) !important;
      padding-right: ceil(($sm-pager-spacing / 2)) !important;

      a {
        margin-bottom: $sm-pager-spacing;
      }
    }
  }

  .slide-bottom {
    @include make-row($sm-pager-spacing);

    [class*=col] {

      // If we are not overriding with !important, the include make-x-column
      // is overriding the custom pager spacing.
      padding-left:  floor(($sm-pager-spacing / 2)) !important;
      padding-right: ceil(($sm-pager-spacing / 2)) !important;
    }
  }
}

// Tablet - only
@media #{$breakpoint-tablet-only} {

  .term-fic-header .slide-bottom .blue-block .text,
  .term-fic-header .slide-bottom .contact.modal-link {
    font-size: 1.3em; }
  .term-fic-header .slide-bottom .blue-block .read-more {
    font-size: 0.75em;
    padding: 12px;
  }
  .term-fic-header {

    .slide-content-wrapper {

      .field-name-description-field,
      .field-name-field-os2web-base-field-summary {
        font-size: 2.5em;
        line-height: 1em;

        padding-top: 40px;
        padding-bottom: 40px;
      }
    }

    //.views_slideshow_main {
    //  height: auto !important;
    //}

    .views_slideshow_cycle_slide {
      width: 100% !important;
    }

    .slide-bottom {
      .blue-block {
        min-height: auto;
      }

      .text {
        font-size: 1.7em;
        line-height: 1em;
      }
    }

    .contact.modal-link {
      font-size: 1.7em;
      line-height: 1em;
    }
  }

  .scroll-down-wrapper {
    height: 90px;

    margin: 0;

    .scroll-down-link {
      display: none;
    }
  }
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {

  .widget_pager_bottom,
  .cycle-pager {
    @include make-row($md-pager-spacing);

    min-height: 80px + $md-pager-spacing;

    > .views-slideshow-pager-field-item,
    > .field-item {
      padding-left:  floor(($md-pager-spacing / 2)) !important;
      padding-right: ceil(($md-pager-spacing / 2)) !important;

      a {
        margin-bottom: $md-pager-spacing;
      }
    }
  }

  .slide-bottom {
    @include make-row($md-pager-spacing);

    [class*=col] {

      // If we are not overriding with !important, the include make-x-column
      // is overriding the custom pager spacing.
      padding-left:  floor(($md-pager-spacing / 2)) !important;
      padding-right: ceil(($md-pager-spacing / 2)) !important;
    }
  }
}

// Desktop - only
@media #{$breakpoint-desktop-only} {
  .term-fic-header .slide-bottom .blue-block .text,
  .term-fic-header .slide-bottom .contact.modal-link  {
    font-size: 1.7em; }
  .term-fic-header .slide-bottom .blue-block .read-more {
    font-size: 0.75em;
    padding: 12px;
  }
  .term-fic-header {

    .slide-content-wrapper {

      .field-name-description-field,
      .field-name-field-os2web-base-field-summary {
        font-size: 2.5em;
        line-height: 1em;

        padding-top: 40px;
        padding-bottom: 40px;
      }
    }

    .views_slideshow_cycle_slide {
      width: 100% !important;
    }

    .views-slideshow-controls-bottom {
      .views-slideshow-pager-field-item {
        span {
          width: auto;
        }
      }
    }

    .slide-bottom {

      .blue-block {
        min-height: auto;
      }

      .text {
        font-size: 1.6em;
        line-height: 1em;
      }
    }

    .contact.modal-link {
      font-size: 1.7em;
      line-height: 1em;
    }
  }

  .scroll-down-wrapper {
    height: 90px;

    margin: 0;

    .scroll-down-link {
      display: none;
    }
  }
}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {

  .widget_pager_bottom,
  .cycle-pager {
    @include make-row($lg-pager-spacing);

    min-height: 80px + $lg-pager-spacing;

    > .views-slideshow-pager-field-item,
    > .field-item {
      padding-left:  floor(($lg-pager-spacing / 2)) !important;
      padding-right: ceil(($lg-pager-spacing / 2)) !important;

      a {
        margin-bottom: $lg-pager-spacing;
      }
    }
  }

  .slide-bottom {
    @include make-row($lg-pager-spacing);

    [class*=col] {

      // If we are not overriding with !important, the include make-x-column
      // is overriding the custom pager spacing.
      padding-left:  floor(($lg-pager-spacing / 2)) !important;
      padding-right: ceil(($lg-pager-spacing / 2)) !important;
    }
  }
}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}

// --------------------------------------------------
// Mixins
// --------------------------------------------------
