// |--------------------------------------------------------------------------
// | Slideshow
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .field-type-image
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Mixins
// --------------------------------------------------

// Banner mixin

// --------------------------------------------------
// Styling
// --------------------------------------------------

.os2-section,
.os2-sectiont {

  article.node--os2web_base_news--full,
  article.node.node-os2web-base-contentpage.view-mode-full.node--os2web_base_contentpage--full,
  article.node-os2web-kulturnaut-knactivity.view-mode-full.node--os2web_kulturnaut_knactivity--full {

    .field-name-field-os2web-kulturnaut-longdesc,
    .field.field-name-field-os2web-base-field-summary.field-type-text-with-summary,
    .field.field-name-body.field-type-text-with-summary,
    .paragraphs-item-afsnit-m-alm-tekss {
      color: #192124;

      h2.h2 {
        color: #022846;
      }

      p, li, h3, h4 {
        color: #192124;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0 0 10px;
        line-height: 1.9em;

        li {

          &:before {
            content: "•\00a0\00a0";
            color: $brand-secondary;
          }
        }
      }

      li {
        padding-left: 1em;
        text-indent: -1em;
      }

      a {
        color: $brand-secondary;
      }
    }
  }
}

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - only
@media #{$breakpoint-mobile-only} {

}
