// |--------------------------------------------------------------------------
// | Footer
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .footer
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Styling
// --------------------------------------------------

// Footer
footer.footer {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  border-top: none;
  font-weight: 300;
  font-size: 15px;
  .footer-dark {
    background-color: $gray-darker;
    color: $color-white;

    .container {
      padding-top: $grid-gutter-width;
      padding-bottom: $grid-gutter-width;
    }

    a {
      color: $color-white;
      text-decoration: underline;
    }
    h2.block-title {
      color: $brand-primary;
      font-size: 2em;
      letter-spacing: 0.019em;
    }
  }
  ul.menu.nav{
    columns: 2;
    li{
      a{
        padding: 0 0 5px 0;
        text-decoration: none;
        font-weight: 300;
        &:active, &:hover, &:focus{
          background: none;
          color: $brand-primary;
        }
      }
    }
  }
}

ul.social-icon-list {
  padding-left: 0;
  list-style: none;
  margin: $grid-gutter-width 0;

  li {
    display: inline;

    a.social-icon {
      text-decoration: none;
      color: $color-black;
      background-color: $color-white;
      font: normal normal normal 2.4em/1 FontAwesome;
      border-radius: 50% 50%;
      padding: 10px 12px;
      margin-right: 10px;
      display: inline-block;
      width: 54px;
      height: 54px;
      text-align: center;

      &.social-icon-linkedin {
        &:before{
          content: "\f0e1";
        }
      }

      &.social-icon-instagram {
        &:before{
          content: "\f16d";
        }
      }

      &.social-icon-youtube {
        &:before{
          content: "\f167";
        }
      }

      &.social-icon-facebook {
        &:before{
          content: $fa-var-facebook;
        }
      }
    }

    &:last-child {

      a.social-icon {
        margin-right: 0;
      }
    }
  }
}

.footer4 {
  margin: $grid-gutter-width 0;
  line-height: 1.6em;
  margin-top: 0;
}

.block-os2web-logo {
  display: inline-block;
  min-width: 160px;
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {


}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  // Footer
  .footer {

    .footer5 {
      text-align: center;
    }
  }
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {
  // Footer
  .footer {
    .footer5 {
      position: relative;
      text-align: right;
      min-height: 54px;

      ul.social-icon-list {
        margin: 0;
        position: absolute;
        bottom: 0;
      }
    }

    .block-os2web-logo {
      margin-left: 200px;
    }
  }
}

// Tablet - only
@media #{$breakpoint-tablet-only} {

  // Footer
  .footer {
  }
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {

  // Footer

}


// Desktop - only
@media #{$breakpoint-desktop-only} {

  // Footer
  .footer {
  }
}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {

  // Footer
  .footer {
    .footer4 {
      margin-top: 63px;
      text-align: left;
      h2{
        margin-top: -43px;
      }
    }
  }
}

// Print
@media print {

  // Footer
  .footer {
  }
}

// Touch
.touchevents {

  // Footer
  .footer {
  }
}

// No touch
.no-touchevents {

  // Footer
  .footer {
  }
}

// --------------------------------------------------
// Mixins
// --------------------------------------------------

// Footer mixin
@mixin footer-mixin() {
}
