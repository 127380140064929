// |--------------------------------------------------------------------------
// | Typography helper
// |--------------------------------------------------------------------------
// |

// --------------------------------------------------
// Letter spacing
// --------------------------------------------------

$headings-letter-spacing: 0;
$letter-spacing: 0;

// --------------------------------------------------
// Font weight
// --------------------------------------------------

$headings-font-weight-light: 400;
$headings-font-weight: 400;
$headings-font-weight-bold: 700;

$font-weight-light: 300;
$font-weight: 400;
$font-weight-bold: 600;
