// |--------------------------------------------------------------------------
// | Spotbox, Spotbox single, Spotbox mini
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .view-mode-spotbox, .view-mode-os2sub_spotbox_single, .view-mode-os2sub_spotbox_mini
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------
$image-border: 10px;
// --------------------------------------------------
// Styling
// --------------------------------------------------
.node--os2web_kulturnaut_knactivity--os2sub_teaser_mini,
.node--os2web_base_news--os2sub_teaser_mini {
  position: relative;

  & .text-contraster {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
    background: linear-gradient(to top, black, transparent);
  }
  & .overlay {
    position: absolute;

    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: .5s ease;

    background-color: rgba($color-black, 0.9);

    .overlay-content {
      padding: 20px;

      text-align: center;

      .field-type-text-with-summary {
        font-size: 1.2em;
        font-family: $font-rbn021a;
        color: #fff;
      }

      a {
        background-color: $color-red;
        font-family: $font-rbn021a;
        font-weight: normal;
        border: 0;
        padding: 5px 10px;
        font-size: 1.5em;
        height: auto;
        width: auto;
        margin-top: 30px;
      }

      div.link-show-more {

        a {
          margin-top: 0;
        }
      }
    }
  }

  &:hover {
    .overlay {
      opacity: 1;
    }
  }

  .field.field-name-field-os2web-kulturnaut-date.field-type-datetime {
    font-size: 0.9em;
    color: $brand-secondary;
    text-transform: uppercase;
  }

  header {
    h2, h3 {
      margin-top: 0;
      text-transform: uppercase;
      font-size: 1.92em;
      font-family: $headings-font-family;
      line-height: 1em;
    }
  }

  .field.field-name-field-os2web-kulturnaut-descrip.field-type-text-with-summary {
    line-height: 1.6em;
  }
}

// Teaser
.node--os2web_kulturnaut_knactivity--os2sub_teaser_mini {

  .overlay-wrapper {
    border: 6px solid $gray-darker;
    position: relative;
    margin-bottom: 27px;
  }
  header {
    h3 {
      margin-bottom: 15px;
      color: $color-dark-gray;
    }
  }
  .link.show-more {
    margin-top: 10px;
    a {
      text-transform: uppercase;
      font-weight: bold;
      &:active, &:hover, &:focus {
        text-decoration: none;
        color: $gray-dark;
      }
    }
  }
}

.node--os2web_base_news--os2sub_teaser_mini {
  border: $image-border solid $gray-darker;
  position: relative;
  overflow: hidden;

  .upper-part {
    position: absolute;
    top: 0;
    right: 0;
    a {
      display: block;
      background-color: $brand-secondary;
      color: #fff;
      font-size: 0.875em;
      line-height: 0.875;
      font-weight: 800;
      text-transform: uppercase;
      padding: 10px;
      &:active, &:hover, &:focus {
        text-decoration: none;
        color: $gray-dark;
      }
    }
  }
  .bottom-part {
    position: absolute;
    bottom: 0;
    margin-left: 20px;
    margin-right: 20px;
    & header {
      & h2 {
        color: #fff;
      }
    }
  }

  .content-sidebar & {
    border: $image-border solid $gray-darker;
  }
}

.big-plus-wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%;

  a.big-plus-link {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: 10px $gray-darker dashed;
    color: $text-color;
    font-family: $headings-font-family;
    font-size: 1.75em;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-transform: uppercase;

    span.link.fa.fa-plus {
      color: $brand-primary;
      font-size: 2.75em;
      line-height: 80px;
      margin-bottom: 0;
    }
    &:hover {
      border-color: $gray-darker;
    }
  }

  .content-sidebar & {

    a.big-plus-link {
      color: $text-color;

      &:hover {
        border-color: $gray-darker;
      }
    }
  }
}

.instagram-logo {
  display: none;
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

.view-os2sub-nyheder,
.view-fic-artikler {
  .views-row {
    margin-bottom: $grid-gutter-width;
  }

  .se-flere {
    border: $image-border dashed $gray-darker;
    &:hover {
      border-color: #fff;
    }
  }
}

.view-fic-embed-nodes article.node {
  margin-bottom: $grid-gutter-width;
}

.bg-white {

  .instagram-logo--default {
    display: block;
  }
}

.bg-dark {

  .instagram-logo--dark {
    display: block;
  }

  .node--os2web_kulturnaut_knactivity--os2sub_teaser_mini {

    .overlay-wrapper {
      border-color: #fff;
    }

    header {

      h3 {
        color: #fff;
      }
    }
  }

  .node--os2web_base_news--os2sub_teaser_mini {
    border-color: #fff;
  }

  .big-plus-wrapper {

    a.big-plus-link {
      border-color: $gray-darker;
      color: #fff;

      span.link.fa.fa-plus {
        color: $brand-primary;
      }

      &:hover {
        border-color: #fff;
      }
    }
  }
}

.bg-darker {

  .instagram-logo--darker {
    display: block;
  }

  .node--os2web_kulturnaut_knactivity--os2sub_teaser_mini {

    .overlay-wrapper {
      border-color: #fff;
    }

    header {

      h3 {
        color: #fff;
      }
    }
  }

  .node--os2web_base_news--os2sub_teaser_mini {
    border-color: #fff;
  }

  .big-plus-wrapper {

    a.big-plus-link {
      border-color: $text-color;
      color: #fff;

      span.link.fa.fa-plus {
        color: $brand-primary;
      }

      &:hover {
        border-color: #fff;
      }
    }
  }
}

.bg-alternative {

  .instagram-logo--alternative {
    display: block;
  }

  .node--os2web_kulturnaut_knactivity--os2sub_teaser_mini {

    .link.show-more {

      a {

        &, &:hover, &:hover, &:focus {
          color: $color-red;
        }
      }
    }
  }

  .big-plus-wrapper {

    a.big-plus-link {
      color: #fff;

      span.link.fa.fa-plus {
        color: $color-red;
      }

      &:hover {
        border-color: #fff;
      }
    }
  }
}

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {

  .node--os2web_kulturnaut_knactivity--os2sub_teaser_mini,
  .node--os2web_base_news--os2sub_teaser_mini {
    & .overlay {
      .overlay-content {
        padding: 10px;
        .field-type-text-with-summary {
          font-size: 0.8em;
        }
      }
    }
  }
}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}

// --------------------------------------------------
// Mixins
// --------------------------------------------------

// Page layout mixin
@mixin content-mixin-inner() {}
