// |--------------------------------------------------------------------------
// | Paragraph
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// |
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Styling
// --------------------------------------------------

.node-os2web-base-contentpage {

  .field-name-field-afsnit {

    .entity-paragraphs-item {
      padding-top: 20px !important;
      padding-bottom: 20px !important;

      background-color: white !important;

      .field-name-field-paragraph-text {
        margin-bottom: 0;
      }
    }
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}

