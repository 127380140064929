// |--------------------------------------------------------------------------
// | 
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | 
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------


// --------------------------------------------------
// Styling
// --------------------------------------------------
.paragraphs-item-afsnit-m-galleri {

  .photoswipe-gallery {

    .field-items {
      @include make-row();

      margin-top: -8px;
      margin-bottom: -8px;

      .field-item {
        padding-top: 8px;
        padding-bottom: 8px;

        @include make-xs-column(6);
        @include make-sm-column(4);
        margin-bottom: ($grid-gutter-width /2);

        img {
          border: $color-black solid 6px;
        }
      }
    }
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {


}
// Mobile - only
@media #{$breakpoint-mobile-only} {


}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {
}

// Tablet - only
@media #{$breakpoint-tablet-only} {


  .social-icons {}
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {




}

// Desktop - only
@media #{$breakpoint-desktop-only} {


}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {


}

// Print
@media print {


}

// Touch
.touchevents {

  // Social icons
  .social-icons {}
}

// No touch
.no-touchevents {

  // Social icons
  .social-icons {}
}

