// |--------------------------------------------------------------------------
// | Page layout
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .content-inner
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// Responsive
$xs-page-layout-content-inner-padding-top: 20px;
$sm-page-layout-content-inner-padding-top: 30px;
$md-page-layout-content-inner-padding-top: 40px;

$xs-page-layout-content-inner-padding-bottom: 25px;
$sm-page-layout-content-inner-padding-bottom: 40px;
$md-page-layout-content-inner-padding-bottom: 60px;

// --------------------------------------------------
// Styling
// --------------------------------------------------

// Page layout

.breadcrumb {
  a{
    color: $gray-light;
    text-decoration: none;
  }
  background: none;
  padding-left: 0;
  .active{
    color: $brand-secondary;
  }
}

.breadcrumb-wrapper {
  padding: 15px 0;

  .breadcrumb {
    margin-bottom: 0;
  }
}

.search-and-title{
  padding-top: 0;
}

/*Some flex panel tweaks start*/
.panels-flexible-region-1-center{
  width: 100% !important;
}
.panel-separator{
  display: none;
}

.page-nyheder .main-container {
  @extend .bg-dark;
}

h2.pane-title {
  padding-bottom: 20px;
  font-size: 3.5715em;
  color: $color-dark-gray;

  a {
    color: $color-dark-gray;
  }
}

// The contact page (/generelle-kontaktoplysninger)
.page-node-31 {

  .modal-link {

    &.ctools-modal-contact-modal-style {
      display: none !important; // Should ONLY be hidden on this page which is why i can reason for a !important.
    }
  }
}


.page-nyheder {
  h1.page-header {
    color: $brand-primary;
    font-size: 50px;
    margin-top: 0;
  }
  .view-os2sub-nyheder .view-content {
    @include make-row();
  }
}

/*Some flex panel tweaks stop*/
.pane-menu-tree {

  li {

    span,
    a {
      font-family: $headings-font-family;
      font-size: 1.75em;
      padding-top: 0;
      padding-bottom: 0;
    }

    a {
      color: $color-black;
    }

    span {
      display: block;

      margin-top: 15px;

      color: $brand-primary;
    }
  }

  .active,
  .active-trail {

    > a {
      color: $brand-secondary;
    }
  }
}
.modal-content.popups-body{
  background-color: #fff;
  header h2 a {
    color: #333;
    font-size: 2em;
  }
}

blockquote {
  border: 0;
  text-align: center;
  font-size: 2em;
  font-family: $font-rbn021a;
  line-height: 1.05em;
  width: 90%;
  margin: auto;
  color: $brand-primary;

  *, &:before, &:after {
    color: $brand-primary !important;
    display: inline;
  }

  &:before {
    content: '“';
  }

  &:after {
    content: '”';
  }
}

hr {
  border-top: 2px solid $color-light-gray;
}
.img-bordered {
  border: 5px solid $color-black;
}

span {
  &.primary-highlighted {
    color: $brand-primary;
  }

  &.secondary-highlighted {
    &, & a {
      color: $brand-secondary;
    }
  }
}
// --------------------------------------------------
// Variants
// --------------------------------------------------



// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {

  // Page layout
  .content-inner {
    padding-top: $xs-page-layout-content-inner-padding-top;
    padding-bottom: $xs-page-layout-content-inner-padding-bottom;
  }

  .row .content-top {
    margin-bottom: 10px;
  }

  h1.page-header,
  h1.pane-title {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  .breadcrumb-wrapper {
    padding: 5px 0;
  }

  // Page layout
  .os2-sectiont {
    .col-sm-3 {
      margin: 40px 0;
    }
  }
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {

  // Page layout
  .content-inner {
    padding-top: $sm-page-layout-content-inner-padding-top;
    padding-bottom: $sm-page-layout-content-inner-padding-bottom;
  }
}

// Tablet - only
@media #{$breakpoint-tablet-only} {

  // Page layout
  .content-inner {}
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {

  // Page layout

  .os2-sectiont {

    .col-md-9 {
      border-left: solid 2px $color-light-gray;
    }
  }
}

// Desktop - only
@media #{$breakpoint-desktop-only} {

  // Page layout
  .content-inner {}
}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {

  // Page layout
  .content-inner {}
}

// Print
@media print {

  // Page layout
  .content-inner {}
}

// Touch
.touchevents {

  // Page layout
  .content-inner {}
}

// No touch
.no-touchevents {

  // Page layout
  .content-inner {}
}

// --------------------------------------------------
// Mixins
// --------------------------------------------------

// Page layout mixin
@mixin content-mixin-inner() {}
