// |--------------------------------------------------------------------------
// | 
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | 
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

$custom-padding-large-vertical: 15px;
$custom-input-height: (ceil($font-size-large * $line-height-large) + ($custom-padding-large-vertical * 2));

// --------------------------------------------------
// Styling
// --------------------------------------------------

.paragraphs-item-afsnit-m-billede {
  padding-bottom: 40px;
  padding-top: 40px;
  text-align: center;

  input[type="text"] {
    display: inline-block;

    height: $custom-input-height;
    padding: $custom-padding-large-vertical $padding-large-horizontal;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214

    margin: 0 0 10px 0;
    border-radius: 0 !important;
    border: 0;
    text-transform: uppercase;
    font-family: $headings-font-family;
    font-size: 1.875em;
    outline: 0;
    text-indent: 0 !important; // override the inline styling set on element
  }

  .btn {
    height: auto;
    @include button-size(9px, $padding-large-horizontal, 1.875em, $line-height-large, $btn-border-radius-large);

    border: 0;
    border-radius: 0;
  }

  .field.field-name-field-paragraph-header {
    font-size: 3.125em;
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {

  .paragraphs-item-afsnit-m-billede{
    padding-bottom: 60px;
    padding-top: 60px;

    form{
      justify-content: center;
    }
  }
}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
