// |--------------------------------------------------------------------------
// | 
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | 
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------


// --------------------------------------------------
// Styling
// --------------------------------------------------
.paragraphs-item-paragrph-address{
  padding-bottom: 40px;
  padding-top: 40px;

  .field.field-name-field-knap-link {

    a {
      @extend .btn;
      @extend .btn-blue;
      margin-bottom: $grid-gutter-width;

      font-family: $headings-font-family;
      font-size: 1.75em;
    }
  }

  .field.field-name-field-paragraph-map {
    margin-bottom: ($grid-gutter-width /2 );

    iframe{
      max-width: 100% !important;
      height: 200px !important;
      border: 6px solid $color-black;
    }
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {


}
// Mobile - only
@media #{$breakpoint-mobile-only} {


}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {

  .paragraphs-item-paragrph-address{
    padding-bottom: 60px;
    padding-top: 60px;
    .field.field-name-field-paragraph-map{
      iframe{
        height: 400px !important;
      }
    }
  }
}

// Tablet - only
@media #{$breakpoint-tablet-only} {


  .social-icons {}
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {




}

// Desktop - only
@media #{$breakpoint-desktop-only} {


}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {


}

// Print
@media print {


}

// Touch
.touchevents {

  // Social icons
  .social-icons {}
}

// No touch
.no-touchevents {

  // Social icons
  .social-icons {}
}

