// |--------------------------------------------------------------------------
// | Navigation
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .navigation-wrapper
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

$navigation-wrapper-bg-color: $body-bg-secondary;

$navigation-top-padding-top-bottom: 20px;
$navigation-left-padding-right-bottom: 15px;

$navigation-bottom-border-width: 4px;
$navigation-bottom-border-color: $color-white;

// --------------------------------------------------
// Styling
// --------------------------------------------------

// Navigation

/*
.topnav-wrapper {
  a{
    font-size: 0.75em;
  }
  .region-navigation,
  ul.menu.nav.navbar-nav  {
    position: relative;
    float: none;
    display: inline-block;
  }
  .user-nav{
    display: flex;
  }
}*/

.topnav-wrapper.user-nav{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
  a{
    font-size: 0.75em !important;
    color: $color-white;

    &.active, &.active-trail,
    &:active, &:hover, &:focus{
      background: none !important;
      color: $brand-primary !important;
    }
  }

  ul.menu {
    float: unset;

    li {
      display: inline-block;
    }

    a{
      font-size: 12px !important;
      font-weight: 800;
    }
  }
  .region.region-navigation{
    display: flex;
    position: relative;
    align-items: center;

    section.block.block-locale.contextual-links-region.block-locale{

      button.lang-buton{
        text-transform: uppercase;
        border: none;
        background: none;
        &:after{
          content:'';
          color: $brand-primary;
          font: normal normal normal 14px/1 FontAwesome;
          border: none;
        }
        &:active, &:hover, &:focus{
        color: $brand-primary;
        }
      }
      .dropdown-menu{
        min-width: auto;
        border: none;
        border-radius: unset;
        top: auto;
        ul.language-switcher-locale-url{
          padding: 0px 10px;
          li{
            list-style: none;
            a{
              text-transform: uppercase;
              text-decoration: none;
              color: $gray-darker;
              &:active, &:hover, &:focus{
                color: $brand-primary;
              }
            }
          }
        }
      }
    }
  }
}
.topnav-wrapper {

  .book-link{
    margin-left: 20px;

    a {
      @extend .btn;
      @extend .btn-blue;
      font-size: 15px !important;
      padding: 2px 15px !important;

      &:hover, &:active, &:focus {
        background-color: $color-blue !important;
        color: $color-white !important;
      }
    }
  }
}
.navbar.container.navbar-default{
  margin-top: 0;
  border: 0;

  .headerwrapper-inner{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    .navbar-collapse{
      padding: 0;
      width: 100%;
      z-index: 7;
      ul.navbar-nav{
        float: right;
        > li {
          a{
            padding: $navigation-top-padding-top-bottom $navigation-left-padding-right-bottom;
            font-family: $headings-font-family;
            font-size: 1.4em;

            .caret {
              display: none;
            }
          }
          > a {

            &.active, &.active-trail{
              background-color: $brand-primary;
              color: $color-white;
              &:active, &:hover, &:focus{
                background-color: $brand-primary;
                color: $color-white;
              }
            }
          }

          a, &.open a{

            color: $gray-dark;
            background-color:$color-white;
            &:active, &:hover, &:focus{
              background-color: $brand-primary;
              color: $color-white;
            }
          }

          ul.dropdown-menu {
            li:nth-child(odd) {
              a {
                background-color: $color-gray;

                &:active, &:hover, &:focus {
                  background-color: $brand-primary;
                }
              }
            }
          }
        }
      }
    }
  }

  ul.dropdown-menu{
    border: none;
    padding: 0;
  }
}

.layout__content {

  .pane-main-menu {
    display: none;
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {

  // Navigation
  .navigation-wrapper {}
}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  // Navigation
  .navigation-wrapper {}
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {

  // Navigation
  .navigation-wrapper {}
}

// Tablet - only
@media #{$breakpoint-tablet-only} {

  .topnav-wrapper {
    .nav > li > a {
      padding: 10px 5px;
    }
  }


  .topnav-wrapper.user-nav {

    .region.region-navigation {

      section.block.block-locale.contextual-links-region.block-locale {

        .dropdown-menu {
          min-width: 100px;

          padding: 0;

          ul {
            padding: 0 !important;
            margin: 0;

            li + li {
              border-top: 1px solid #eee;
            }
          }

          a {
            display: block;

            padding: 8px 15px;

            font-size: $font-size-base !important;

            &.active {

              &, &:hover {
                color: $brand-primary;
              }
            }
          }
        }
      }
    }
  }
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {

  .layout__content {

    .pane-main-menu {
      display: block;
    }
  }
}

// Desktop - only
@media #{$breakpoint-desktop-only} {

  // Navigation
  .navigation-wrapper {}
}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {
  .headerwrapper-inner {
    .navbar-collapse {
      margin-right: 60px;
    }
  }
  .topnav-wrapper{
    .region.region-navigation{
      .book-link{
        margin-right: 60px;
      }
    }
  }
  // Navigation
  .navigation-wrapper {}
}

// Print
@media print {

  // Navigation
  .navigation-wrapper {}
}

// Touch
.touchevents {

  // Navigation
  .navigation-wrapper {}
}

// No touch
.no-touchevents {

  // Navigation
  .navigation-wrapper {}
}

// --------------------------------------------------
// Mixins
// --------------------------------------------------

// Navigation mixin
@mixin mixin-navigation-wrapper() {}
