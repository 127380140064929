// |--------------------------------------------------------------------------
// | Variables
// |--------------------------------------------------------------------------
// |

// --------------------------------------------------
// List item
// --------------------------------------------------

$navigation-list-item-height: 86px;
$navigation-list-item-font-size: $font-size-large;

// --------------------------------------------------
// Dropdown menu
// --------------------------------------------------

$navigation-list-dropdown-menu-background-color: #333;
$navigation-list-dropdown-menu-box-shadow: none;

$navigation-list-dropdown-menu-item-font-color: #fff;
