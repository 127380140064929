// |--------------------------------------------------------------------------
// | 
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | 
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Styling
// --------------------------------------------------

.paragraphs-item-header-text-media {
  padding-bottom: $grid-gutter-width;
  padding-top: $grid-gutter-width;

  .field-name-field-paragraph-header {
    color: $color-red;
  }

  .field-name-field-image {
    border: 6px solid $color-black;

    img {
      width: 100%;
      height: auto;
    }

    // Responsive embed (look at Bootstrap responsive-embed.scss)
    .file-video {
      position: relative;
      display: block;
      height: 0;
      padding: 0;
      overflow: hidden;

      padding-bottom: 56.25%; // .embed-responsive-16by9

      .embed-responsive-item,
      iframe,
      embed,
      object,
      video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        border: 0;
      }
    }
  }

  .position-of-media--centered {

    .row + .row {
      margin-top: $grid-gutter-width;
    }
  }

  .position-of-media--left,
  .position-of-media--right {

    [class*='col-'] {
      justify-content: center;
    }
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

.bg-dark {

  &.paragraphs-item-header-text-media {

    .field-name-field-image {
      border-color: #fff;
    }
  }
}

.bg-darker {

  &.paragraphs-item-header-text-media {

    .field-name-field-image {
      border-color: #fff;
    }
  }
}

.bg-alternative {

  &.paragraphs-item-header-text-media {

    .field-name-field-paragraph-header {
      color: #fff;
    }
  }
}

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {

  .paragraphs-item-header-text-media {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
