// |--------------------------------------------------------------------------
// | Sidr
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .sidr
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Mixins
// --------------------------------------------------

@mixin sidr-mixin() {}

// --------------------------------------------------
// Styling
// --------------------------------------------------

.sidr {
  z-index: 2000;

  .logo {

    img {
      max-height: 80px;
    }
  }

  .logo + .slinky-menu {
    margin-top: floor( ( $grid-gutter-width / 2 ) );
  }
}

.layout__wrapper {
  box-shadow: none !important; // Reset from sidr bootstrap theme
}

.sidr__toggle {
  border: 0;
}

.sidr {

  .region-navigation {
    display: flex;

    min-height: 40px;

    padding: $slinky-menu-link-inner-top-bottom-padding 15px;

    color: #fff;
    border-bottom: 1px solid #2a2f36;

    a {
      text-transform: uppercase;
    }

    > * {
      flex-basis: 50%;
    }

    .book-link {
      flex-grow: 1;

      text-align: right;

      a {
        font-weight: $font-weight-bold !important;
      }
    }

    section.block-locale {
      position: relative;

      float: none; // reset

      button.lang-buton {
        padding: 0;

        &, span {
          font-family: $font-family-base;
          font-size: $font-size-base;
          font-weight: $font-weight-bold !important;
          text-shadow: none;
        }

        span {
          margin-right: 5px;
        }
      }
    }

    .dropdown-menu {
      min-width: 100px;

      padding: 0;

      ul {
        padding: 0 !important;
        margin: 0;

        li + li {
          border-top: 1px solid #eee;
        }
      }

      a {
        display: block;

        padding: 8px 15px;

        font-size: $font-size-base !important;

        &.active {

          &, &:hover {
            color: $brand-primary;
          }
        }
      }
    }
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  .sidr {

    .region-navigation {

      section.block-locale {

        button.lang-buton {

          .visible-xs {
            display: inline-block !important; // overrides the display:block !important from .visible-xs
          }
        }
      }
    }
  }
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}
