// |--------------------------------------------------------------------------
// | os2web-kulturnaut-events-lists
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .view-os2web-kulturnaut-events-lists
// |


// --------------------------------------------------
// Variables
// --------------------------------------------------

//size of header in desktop orientation and up
// --------------------------------------------------
// Styling
// --------------------------------------------------
.activities-list-background{
  background-color: $color-dark-gray;
  padding-top: 120px !important;
  padding-bottom: 120px !important;
  .activities-list-container{
    float: unset;
    .panels-flexible-region-inside{
      @include make-row();
    }
  }
}

.panel-pane.pane-node-title{
  .os2-box{
    .os2-box-body.pane-content{
      h1.pane-title{
        font-size: 3.375em;
      }
    }
  }
}

.field-name-field-afsnit-full-width,
.pane-paragraphs-from-section { // These are also full width paragraphs

  .entity {

    .content {
      @include container-fixed;

      @media (min-width: $screen-sm-min) {
        width: $container-sm;
      }
      @media (min-width: $screen-md-min) {
        width: $container-md;
      }
      @media (min-width: $screen-lg-min) {
        width: $container-lg;
      }
    }
  }
}

.pane-menu-tree {

  .menu {

    > li {

      > ul {
        padding-left: 15px;
        margin-bottom: 10px;
      }

      span,
      a {
        display: block;

        padding-left: 15px;
        padding-right: 15px;
      }
    }

    ul {
      padding-left: 15px;

      span,
      a {
        font-size: 1.4em !important;
      }
    }
  }
}

.pane-node-field-os2web-kulturnaut-date {

  .date-display-end {
    display: block;
  }

  .date-display-single {

    .date-display-start,
    .date-display-end {
      display: inline-block;
    }
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  .page-node {

    .pane-menu-tree {
      display: none !important;
    }
  }
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}



