// |--------------------------------------------------------------------------
// | view-os2sub-kulturnaut-multi-search
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .view-os2sub-kulturnaut-multi-search
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Styling
// --------------------------------------------------
.page-soeg h2.pane-title {
  color: $brand-primary;
}

.view.view-id-os2sub_kulturnaut_multi_search.view-display-id-pane_activities_multi_search {
  .views-row {
    margin-top: 35px;
    margin-bottom: 35px;
    .taxonomy-term,
    article {
      .field.field-name-field-os2web-base-field-image.field-type-image,
      .field.field-name-field-os2web-base-field-photo.field-type-image,
      .field.field-name-field-os2web-kulturnaut-slidesho.field-type-image,
      .field.field-name-field-os2web-base-field-lead-img.field-type-image {
        border: solid 6px $color-black;
        margin-top: 6px;
      }
    }
    & *, header {
      h2 {
        margin: 0;

        a {
          color: $color-dark-gray;
          font-size: 38px;
          font-family: $headings-font-family;
          text-transform: uppercase;
          font-weight: normal;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .field.field-name-field-sektion.field-type-taxonomy-term-reference {
      a {
        color: $brand-secondary;
        text-transform: uppercase;
        font-size: 1em;
        font-family: $font-open-sans;
        font-weight: bold;
      }
      .field-item {
        display: inline;
        margin-right: 5px;

        + .field-item:before {
          // [converter] Workaround for https://github.com/sass/libsass/issues/1115
          $nbsp: "\00a0";
          content: "-#{$nbsp}"; // Unicode space added since inline-block means non-collapsing white-space
          padding: 0 5px;
          color: $brand-secondary;
        }
      }
    }
    .field.field-name-field-os2web-kulturnaut-date.field-type-datetime {
      text-transform: uppercase;
      font-family: $font-open-sans;
      font-weight: bold;
      color: #a18f81;
    }
    a.read-more {
      text-transform: uppercase;
      font-family: $font-lato;
      font-size: 1em;
      font-weight: bold;
    }
    .top-part {
      display: block;

      padding-top: 10px;

      span {
        display: block;
        &:after {
          content: '\00a0|\00a0';
        }
      }
    }
  }

  .pager--infinite-scroll {
    a {
      @extend .btn;
      @extend .btn-blue;
      font-size: 1.75em;
      display: flex !important;
      justify-content: center;
      align-items: center;
      font-family: $headings-font-family;
      &:before {
        content: '+\00a0';
        font-size: 2em;
        line-height: 0.8em;
      }
    }
  }
}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  .page-soeg h2.pane-title {
    padding-bottom: 0;

    font-size: 34px;
  }

  .view.view-id-os2sub_kulturnaut_multi_search.view-display-id-pane_activities_multi_search {
    .views-row {
      & *, header {
        h2 {

          a {
            font-size: 34px;
          }
        }
      }
    }
  }
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {

}
