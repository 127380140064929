// |--------------------------------------------------------------------------
// | Modal popups
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - navbars
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Styling
// --------------------------------------------------
.navbar.container {
  max-width: none;
}

.navbar-default {
  background-color: transparent;
  border-color: transparent;

  .navbar-collapse {
    border: none;
  }

  .navbar-brand {
    background-color: $color-white;
    margin-right: 30px;
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {

}

// Mobile - only
@media #{$breakpoint-mobile-only} {
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {
}

// Tablet - only
@media #{$breakpoint-tablet-only} {
  .navbar.container.navbar-default .headerwrapper-inner .navbar-collapse {
    margin-right: 0;
    width: 100%;
    margin-top: 10px;
  }
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {
  //trick to mane menu clickable on the front page because of background slideshow

}

// Desktop - only
@media #{$breakpoint-desktop-only} {

}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {
}

// Print
@media print {
}

// Touch
.touchevents {
}

// No touch
.no-touchevents {
}

// --------------------------------------------------
// Mixins
// --------------------------------------------------

