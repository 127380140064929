/* Main colors */

/*rgb(255, 66, 0);*/
$color-red: $brand-secondary;

/*rgb(1, 181, 213);*/
$color-blue: $brand-primary;

/*rgb(42, 47, 54);*/
$color-dark-gray: #2a2f36;

/*rgb(233, 231, 232);*/
$color-gray: #E9E7E8;

/*rgb(204, 204, 204);*/
$color-light-gray: #CCCCCC;

/*rgb(25, 33, 36);*/
$color-black: #192124;

/*rgb(255, 255, 255);*/
$color-white: #ffffff;
