// |--------------------------------------------------------------------------
// | os2web-kulturnaut-events-lists
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .view-os2web-kulturnaut-events-lists
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

//size of header in desktop orientation and up
// --------------------------------------------------
// Styling
// --------------------------------------------------

.node-type-os2web-kulturnaut-knactivity .pane-fic-kommende-arrangementer-panel-pane-1 {
  h2.pane-title{
    margin-bottom: 12px;
  }

  .view-content {
    @include make-row();
  }
}

// Add to calendar

/* General */

// Desktop - only
@media #{$breakpoint-desktop-only} {

  // Banner
  .banner {
  }
}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {

  // Banner
  .banner {
  }
}

// Print
@media print {

  // Banner
  .banner {
  }
}

// Touch
.touchevents {

  // Banner
  .banner {
  }
}

// No touch
.no-touchevents {

  // Banner
  .banner {
  }
}



