// |--------------------------------------------------------------------------
// | Spotbox, Spotbox single, Spotbox mini
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .view-mode-spotbox, .view-mode-os2sub_spotbox_single, .view-mode-os2sub_spotbox_mini
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Styling
// --------------------------------------------------

// Teaser



.view-mode-os2sub_spotbox_single.node {
  .overlay-wrapper {
    position: relative;

  }
  & .overlay{
    & .field.field-name-field-os2web-kulturnaut-url{

    }
  }

  &.node-os2web-kulturnaut-knactivity {
    .field-name-field-os2web-kulturnaut-slidesho {
      max-height: 250px;
      overflow: hidden;
      border: 10px solid $color-white;
    }
    .overlay {

      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      background-color: rgba($color-red, 0.75);

      .overlay-content {
        text-align: center;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        padding: 20px;
        .field-name-field-os2web-kulturnaut-url {
          margin-bottom: 13px;
        }
        & .btn{
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }

    &:hover {
      .overlay {
        opacity: 1;

      }
    }
  }
}

.view-mode-os2sub_spotbox_list.node {
  &.node-os2web-kulturnaut-knactivity {
    margin-bottom: 30px;
    margin-left: 100px;
  }
  header h2 {
    font-size: 2em;
    font-family: RobotoSlab;
    font-weight: normal;
    margin-bottom: 3px;
  }
  .field-name-field-os2web-kulturnaut-date {
    font-size: 1.063em;
    font-family: Lato;
    font-weight: bold;
    color: $color-black;
    padding-bottom: 15px;
    float: left;
    text-transform: lowercase;
  }
  .field-name-field-os2web-kulturnaut-date:first-letter {
    text-transform: uppercase;
  }
  .field-name-field-os2web-kulturnaut-venue {
    a{
      font-size: 1.063em;
      font-family: Lato;
      font-weight: bold;
      color: $color-black;
      padding-bottom: 15px;
      margin-left: 5px;
    }
  }
  .link{
    &.show-more {
      a {
        color: $color-blue;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

}

.event-list-4-show-all{
  .event-list-4-article{

    margin-bottom: 0px;

    .event-list-4{
      max-height: 250px;
      border: 10px dashed $color-black;
      width: 100%;
      //padding-top: 100%;

      min-height: 100%;
      color: white;
      font-size: 1em;
      text-transform: uppercase;
      position: relative;
      margin-bottom: -15px;

      .event-list-4-bottom-part{
        position: absolute;
        top: 50%;
        width: 100%;
        .event-list-4-text{

          text-align: center;
          span.event-list-4-pluss{
            display: block;
            margin-bottom: 20px;
            color: $color-blue;
            font-weight: bold;
            font-size: 3.18em;
            line-height: 0.5em;
          }
        }
      }
      &:hover{
        border-color: $color-white;
      }
    }
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------


// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {




}

// Mobile - only
@media #{$breakpoint-mobile-only} {


}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {


}

// Tablet - only
@media #{$breakpoint-tablet-only} {


}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {

  .view-mode-spotbox.node,
  .view-mode-os2sub_spotbox_single.node,
  .view-mode-os2sub_spotbox_mini.node {
    &.node-os2web-kulturnaut-knactivity {
      .view-display-id-pane_activities_list_4 & {
        .field-name-field-os2web-kulturnaut-date {
          span.date-display-single{
            font-size: 0.9em;
          }
        }
        header h2 {
          font-size: 1.56em;
        }
        .field-name-field-os2web-kulturnaut-venue {
          display: block;
        }
      }

      .view-display-id-pane_activities_list_2_3 & {
        .field-name-field-os2web-kulturnaut-date {
          font-size: 1em;
          color: $color-red;
          font-weight: normal;
          text-transform: lowercase;
        }
        header h2 {
          font-size: 1.75em;
        }
      }

      .view-display-id-pane_activities_list_5_load_more & {
        .field-name-field-os2web-kulturnaut-date {
          span.date-display-single{ 
            font-size: 1.125em;
            color: $color-blue;
            font-weight: normal;
            text-transform: lowercase;
            display: block;
          }
          span.date-display-single:first-letter{
            text-transform: uppercase;
          }
        }
        header h2 {
          font-size: 1.75em;
        }
        .field-name-field-os2web-kulturnaut-venue{
          font-size: 1.125em;
          font-weight: bold;
          display: block;
        }
      }
      .field-name-field-os2web-base-field-edi-tags {
        font-size: 1.125em;
        font-weight: 900;
      }
    }

  }
  .view-mode-os2sub_spotbox_single.node{
    &.node-os2web-kulturnaut-knactivity {
      .field-name-field-os2web-kulturnaut-slidesho {
        max-height: none;
        border: 10px solid $color-white;
      }
    }
  }

  .event-list-4-show-all{
    .event-list-4-article{
      .event-list-4{
        max-height: unset;
      }
    }
  }
}
// Desktop - only
@media #{$breakpoint-desktop-only} {



}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {


}

// Print
@media print {


}

// Touch
.touchevents {

  // Page layout
  .content-inner {}
}

// No touch
.no-touchevents {


}
// --------------------------------------------------
// Mixins
// --------------------------------------------------

// Page layout mixin
@mixin content-mixin-inner() {}
