// |--------------------------------------------------------------------------
// | Header
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .header
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Styling
// --------------------------------------------------

.region-header {
  @include make-row;
}
.page-header-wrapper {
  z-index: 100 !important;
}
.not-front .page-header-wrapper {
  background-image: url('../img/topbg.png');
  background-repeat: repeat-x;
}

.header-container {
  position: relative;

  .header-search-form {
    position: absolute;
    right: 0;
    top: 120px;

    form {
      line-height: 30px;
      background-color: $color-red;
      width: 300px;

      .form-text {
        background-color: transparent;
        border: none;
      }

      .btn.btn-primary {
        background-color: transparent;
        border: transparent;
      }
    }
  }
}

#views-exposed-form-os2sub-kulturnaut-multi-search-pane-activities-multi-search,
form.search-form{
  input.form-control.form-text{
    font-size: 1.5em;
    font-family: $headings-font-family;
    color: #fff;
    box-shadow: none;
    &::placeholder{
      color: #fff;
    }
  }
  .views-exposed-widgets{
    position: relative;
    .views-exposed-widget.views-widget-filter-search_api_views_fulltext{
      padding: 0;
      width: 100%;
    }
    .views-exposed-widget.views-submit-button{
      position: absolute;
      right: 0;
      font-size: 0;
      top: 6px;
      & button{
        box-shadow: none;
        font-size: 1.31em;
        padding: 10px !important;
        width: 60px !important;
        outline: none;
        &:active, &:hover, &:focus{
          color: $gray-dark;
        }
        &:after{
          content: "\e003";
          font-size: 20px;
          font-family: 'Glyphicons Halflings';

        }
      }
    }
  }
}
section.block-locale{
  float: left;
  button.lang-buton{
    text-transform: uppercase;
    border: none;
    background: none;
    font-size: 0.75em;
    color: #fff;
    &:after{
      content:'';
      color: $brand-primary;
      font: normal normal normal 14px/1 FontAwesome;
      border: none;
    }
    &:focus {
      outline: none;
    }
  }
  .dropdown-menu{
    min-width: auto;
    border: none;
    border-radius: unset;
    top: auto;
    ul.language-switcher-locale-url{
      padding: 0 10px;
      li{
        list-style: none;
        a{
          font-size: 0.75em;
          text-transform: uppercase;
          text-decoration: none;
          background-color: #fff;
          color: $gray-darker;
          &:active, &:hover, &:focus{
            color: $brand-primary;
          }
        }
      }
    }
  }
}
.logo-wrapper .logo img {
  max-width: 100%;
  height: auto;
}

.headerwrapper-inner {
  height: 60px;
  margin-bottom: 0;
}
// --------------------------------------------------
// Variants
// --------------------------------------------------
.page-nyheder .header-container {

  h1 {
    margin-top: 0;
    border: 0;
  }

  .search-and-title {
    margin-bottom: 30px;
  }
}
// Sticky menu styles.
.sticky {
  background-color: transparent;
  background-image: linear-gradient(rgba(0, 0, 0, .6) 20%, transparent 100%);

  position: fixed;
  left: 0;
  right: 0;
  z-index: 1100;
}
.backstretched {
  .region-header {
    margin-top: 120px;
  }
  .header-container {
    padding-bottom: 0;
  }
}
.region-header {
  margin-top: 0;
}
.header-container {
  padding-bottom: 120px;
}

.pane-fic-artikler-panel-pane-1 {
  .pane-content.paragraphs-container {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.sidr__toggle {

  .fa-bars {

    .sidr-open & {
      display: none;
    }
  }

  .fa-times {
    display: none;

    .sidr-open & {
      display: inline-block;
    }
  }
}

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  .not-front {

    .page-header-wrapper {
      padding-bottom: 15px;

      background-color: transparent;
      background-image: linear-gradient(
                      to bottom,
                      rgba($color-black, .6) 10%,
                      rgba($color-black, .4) 50%,
                      transparent 100%
      );
    }
  }

  .header-container {

    .page-node & {
      padding-bottom: 30px;
    }
  }
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {

  .not-front .page-header-wrapper {
    background-image: none;
  }

  .sticky {
    @include container-fixed();
    @include gradient-vertical(rgba($color-black,1), rgba($color-black,0), 0%, 100%);

    padding-bottom: 0;

    .row{
      margin: auto;
      width: 750px;
    }
  }

  #views-exposed-form-os2sub-kulturnaut-multi-search-pane-activities-multi-search,
  form.search-form {
    height: 60px;
    input.form-control.form-text {
      height: 60px;
    }
  }

  .backstretched {
    .region-header {
      margin-top: 250px;
    }
  }
  .header-container {
    padding-bottom: 180px;
  }
}

// Tablet - only
@media #{$breakpoint-tablet-only} {
  .navbar-nav > li > a {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
  .topnav-wrapper.user-nav {
    //height: 42px !important;
  }
  .navbar.container.navbar-default .headerwrapper-inner .navbar-collapse {
    margin-top: 0;
  }
  .view-mode-fic_header .field-name-field-os2web-base-field-ext-link {
    display: none;
  }

  .header-container .header-search-form {
    top: 130px;
  }

  .logo-wrapper {

    .logo {
      position: absolute;

      top: 0;
      left: 0;
      bottom: 0;
    }
  }
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {
  //trick to mane menu clickable on the front page because of background slideshow
  .sticky {
    background-position: 0;

    .row{
      margin: auto;

      width: 970px;
    }
  }

  .backstretched {
    .region-header {
      margin-top: 200px;
    }
  }
  .header-container {
    padding-bottom: 130px;
  }
}

// Desktop - only
@media #{$breakpoint-desktop-only} {
  .navbar-nav > li > a {
    padding-top: 0;
    padding-bottom: 0;
  }
  .topnav-wrapper.user-nav {
    height: 46px !important;
  }
  .view-mode-fic_header .field-name-field-os2web-base-field-ext-link {
    display: none;
  }

  .header-container .header-search-form {
    top: 106px;
  }
}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {
  .sticky .row{
    width: 1200px;
  }
}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}

// --------------------------------------------------
// Mixins
// --------------------------------------------------

// Header mixin
@mixin header-mixin() {
}
