// |--------------------------------------------------------------------------
// | Navigation bar
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .navigation-bar
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Mixins
// --------------------------------------------------

@mixin navigation-bar-mixin() {}

// --------------------------------------------------
// Styling
// --------------------------------------------------

.navigation-bar {
  display: none;

  margin-top: floor( ($grid-gutter-width / 2) );
}
.navigation-bar__inner {}

.navigation-bar__menu {
  position: relative;

  background-color: $brand-primary;

  ul {
    @include list-unstyled;

    .nolink {
      display: none;
    }

    a {
      font-family: $headings-font-family;
      font-size: 16px;
      text-transform: uppercase;
      color: #fff;

      &.active, &.active-trail,
      &:hover, &:focus, &:active {
        background-color: $brand-primary;
      }
    }

    ul {
      display: none; // hide subnavigations
    }
  }

  .dropdown-toggle {

    .caret {
      display: none;
    }
  }
}

.navigation-bar__toggle {
  padding: 10px;

  font-family: $headings-font-family;
  font-size: 18px;
  text-transform: uppercase;

  color: #fff;

  cursor: hand;
  cursor: pointer;

  &:after {
    position: absolute;

    @extend .icon;

    bottom: 14px;
    right: 10px;

    content: $fa-var-angle-down;

    font-size: 20px;
    color: #fff;
  }

  .visible &:after {
    content: $fa-var-angle-up;
  }

  .page-taxonomy-term &:after {
    content: $fa-var-angle-up;
  }

  .page-taxonomy-term .visible &:after {
    content: $fa-var-angle-down;
  }
}

.navigation-bar__dropup {
  position: absolute;

  z-index: $zindex-dropdown;

  display: none;

  top: 100%;
  left: 0;
  right: 0;

  background-color: $color-black;

  .page-taxonomy-term & {
    top: auto;
    bottom: 100%;

    overflow-y: scroll;

    max-height: 410px;
  }

  .visible & {
    display: block;
  }

  ul {

    > li {
      border-bottom: 1px solid #2a2f36;
    }

    a {
      padding: 10px;

      text-align: center;
    }
  }
}

.navigation-bar__toggle__text {
  display: none;
}
.navigation-bar__toggle__text--additional-items {

  body:not(.page-taxonomy-term) & {
    display: block;
  }
}
.navigation-bar__toggle__text--all-in-sections {

  .page-taxonomy-term & {
    display: block;
  }
}

.front {

  .navigation-bar {
    display: none !important;
  }
}

.page-taxonomy-term.not-front {

  .navigation-bar {
    display: block !important;
  }
}

.page-node {

  .navigation-bar {
    display: block !important;
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  .navigation-bar {
    @include container-fixed;
  }
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {}

// Tablet - only
@media #{$breakpoint-tablet-only} {

  .navigation-bar {
    @include container-fixed;

    width: $container-sm;
  }
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {

  .page-node {

    .navigation-bar {
      display: none !important;
    }
  }

  .navigation-bar {
    margin-top: 0;

    max-height: 81px;
    overflow: hidden;
  }

  .navigation-bar__inner {}

  .navigation-bar__menu {
    text-align: center;

    background-color: $color-black;

    ul {

      > li {
        display: inline-block;

        margin: 0;

        + li {
          margin-left: -5px;
        }

        > a {
          padding: 28px 40px;
        }
      }
    }
  }
}

// Desktop - only
@media #{$breakpoint-desktop-only} {}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {}

// Print
@media print {}

// Touch
.touchevents {}

// No touch
.no-touchevents {}