// |--------------------------------------------------------------------------
// | Contact block
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | - .kontaktblok
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------

// --------------------------------------------------
// Styling
// --------------------------------------------------

// Contact block
.kontaktblok {

  .kontaktblok-headline {
    @extend .heading-h3;
  }

  .kontaktblok-text {
    font-size: $font-size-large;
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {

  // Contact block
  .kontaktblok {
  }
}

// Mobile - only
@media #{$breakpoint-mobile-only} {

  // Contact block
  .kontaktblok {
  }
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {

  // Contact block
  .kontaktblok {
  }
}

// Tablet - only
@media #{$breakpoint-tablet-only} {

  // Contact block
  .kontaktblok {
  }
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {

  // Contact block
  .kontaktblok {
  }
}

// Desktop - only
@media #{$breakpoint-desktop-only} {

  // Contact block
  .kontaktblok {
  }
}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {

  // Contact block
  .kontaktblok {
  }
}

// Print
@media print {

  // Contact block
  .kontaktblok {
  }
}

// Touch
.touchevents {

  // Contact block
  .kontaktblok {
  }
}

// No touch
.no-touchevents {

  // Contact block
  .kontaktblok {
  }
}

// --------------------------------------------------
// Mixins
// --------------------------------------------------

// Contact block mixin
@mixin kontakt-mixin-block() {
}
