// By @mn
// I really don't know why but the <body> has - perhaps the longest -
// selector ever which defines a margin-left: -1px;.
// This causes troubles on Chrome ex.
// Take a look at FKDK-1085.
body {
  margin-left: 0 !important;
}

.form-control {
  font-size: $font-size-large;

  // Remove inline shadow on iOS (https://stackoverflow.com/questions/23211656/remove-ios-input-shadow)
  appearance: none;

  &,
  &:focus {
    @include box-shadow(none);
  }
}

.pswp__bg {
  background: rgba(0, 0, 0, 0.9);
}

.sidr-main-open {
  position: static !important;
}

.html #sliding-popup {
  .popup-content,
  #popup-text,
  #popup-buttons {
    max-width: none;
  }

  &.sliding-popup-bottom {
    background: $color-blue;
  }

  .popup-content {
    @include container-fixed;

    @media (min-width: $screen-sm-min) {
      width: $container-sm;
    }
    @media (min-width: $screen-md-min) {
      width: $container-md;
    }
    @media (min-width: $screen-lg-min) {
      width: $container-lg;
    }

    #popup-text {
      margin: 15px 0;

      h2 {
        font-size: 2em;
        font-weight: normal;
        color: $color-black !important;
        margin: 0;
      }

      p {
        font-size: 1.1em;
        line-height: 1.2em;
        font-weight: normal;
        color: $color-white !important;
        margin: 0;
      }
    }

    #popup-buttons {
      float: left;

      button {
        @extend .btn;
        @extend .btn-small;
        font-family: $font-rbn021a;
        font-size: 1.4em;
        font-weight: normal;
        text-shadow: none;
        box-shadow: none;
        margin-top: 1.5em;
        min-width: 45%;

        &.agree-button {
          @extend .btn-white;
        }

        &.find-more-button {
          @extend .btn-black;
        }
      }
    }
  }
}

// Flexbox
.flexbox {

  .row--equal-height-columns {

    &:not(.view),
    > .view-content {
      display: flex !important;

      flex-wrap: wrap;

      > [class*='col-'] {
        display: flex;

        flex-direction: column;
      }

      // Fixes Safari issue (https://stackoverflow.com/questions/34250282/flexbox-safari-bug-flex-wrap)
      &:after,
      &:before {
        content: normal !important; // IE doesn't support `initial`
      }
    }
  }
}
