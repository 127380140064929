// --------------------------------------------------
// Secondary
// --------------------------------------------------

$brand-secondary: #ff4200;

// Button
$btn-secondary-color: #fff;
$btn-secondary-bg: $brand-secondary;
$btn-secondary-border: darken($btn-secondary-bg, 5%);

// Label
$label-secondary-bg: $brand-secondary;

// Panel
$panel-secondary-text: #fff;
$panel-secondary-border: $brand-secondary;
$panel-secondary-heading-bg: $brand-secondary;

// --------------------------------------------------
// Tertiary
// --------------------------------------------------

$brand-tertiary: #89bd2a;

// Button
$btn-tertiary-color: #fff;
$btn-tertiary-bg: $brand-tertiary;
$btn-tertiary-border: darken($btn-tertiary-bg, 5%);

// Label
$label-tertiary-bg: $brand-tertiary;

// Panel
$panel-tertiary-text: #fff;
$panel-tertiary-border: $brand-tertiary;
$panel-tertiary-heading-bg: $brand-tertiary;
