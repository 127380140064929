// --------------------------------------------------
// Styling
// --------------------------------------------------
body.page-search.page-search-content{

  section.block.block-system.block-system{
    & > .row{
      margin-top: 30px;
      margin-bottom: 30px;
    }
    & > form{
      button.btn.btn-primary{
        @extend .btn;
        @extend .btn-blue;
        width: 60px !important;
        height: 60px !important;
      }

      input{
        color:  $gray-dark;
        &::placeholder{
          color: $gray-dark;
        }
      }
    }
  }

  .field.field-name-field-os2web-kulturnaut-date{
    color: #a18f81;
    span{
      float: left;
      &:after{
        content: '|';
        margin-left: 0.5em;
        margin-right: 0.5em;
      }
    }
  }

  .field.field-name-field-sektion.field-type-taxonomy-term-reference{
    color: $brand-secondary;
  }

  .field.field-name-title{
    h2{
      text-transform: uppercase;
      font-size: 3.375em;
    }
  }

  .field.field-name-search-snippet{
    font-size: 1em;
  }

  .field.field-name-node-link{
    a{
      text-transform: uppercase;
      color: $brand-primary;
      font-weight: bold;
      font-size: 1em;
    }
  }

  .field.field-name-field-os2web-kulturnaut-slidesho{
    img{
      border: 6px solid $gray-darker;
    }
  }

  .field.field-name-field-os2web-kulturnaut-date,
  .field.field-name-field-sektion.field-type-taxonomy-term-reference{
    font-family: $font-open-sans;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bold;
  }
}


// --------------------------------------------------
// Variants
// --------------------------------------------------

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {


}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {

}