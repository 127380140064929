// |--------------------------------------------------------------------------
// | os2web-kulturnaut-tema-list
// |--------------------------------------------------------------------------
// |
// | This file contains styles for the following:
// | -
// |

// --------------------------------------------------
// Variables
// --------------------------------------------------// --------------------------------------------------
// Styling
// --------------------------------------------------

.paragraphs-item-fic-list-of-event-teasers,
.views-container.full-width.pane-fic-kommende-arrangementer-panel-pane-1,
.view.view-fic-kommende-arrangementer.view-id-fic_kommende_arrangementer {

  .more-link {

    a {
      @extend .btn;
      @extend .btn-blue;
      font-size: 1.75em;
      display: flex !important;
      justify-content: center;
      align-items: center;
      font-family: $headings-font-family;

      &:before {
        content: '+\00a0';
        font-size: 2em;
        line-height: 0.8em;
      }
    }
  }
}

.file-instagram {
  margin-bottom: $grid-gutter-width;

  // Sorry for the !importants. This is to override an @extend in panel-pane.scss:40
  .content {
    display: block !important;

    padding-left: 0 !important;
    padding-right: 0 !important;
    width: auto !important;
  }

  img {
    border: 10px solid $color-dark-gray;
    width: 100%;
  }
}

// --------------------------------------------------
// Variants
// --------------------------------------------------

.bg-dark {

  .file-instagram {

    img {
      border-color: #fff;
    }
  }
}

.bg-darker {

  .file-instagram {

    img {
      border-color: #fff;
    }
  }
}

.bg-alternative {

  &.paragraphs-item-fic-list-of-event-teasers,
  .views-container.full-width.pane-fic-kommende-arrangementer-panel-pane-1,
  .view.view-fic-kommende-arrangementer.view-id-fic_kommende_arrangementer {

    .more-link {

      a {
        @extend .btn-red;
      }
    }
  }
}

// --------------------------------------------------
// Responsive
// --------------------------------------------------

// Mobile - and up
@media #{$breakpoint-mobile-and-up} {
}

// Mobile - only
@media #{$breakpoint-mobile-only} {
}

// Tablet - and up
@media #{$breakpoint-tablet-and-up} {
}

// Tablet - only
@media #{$breakpoint-tablet-only} {
}

// Desktop - and up
@media #{$breakpoint-desktop-and-up} {

  .view-fic-embed-nodes.view-display-id-news,
  .views-container.full-width.panel-pane.pane-views-panes.pane-fic-artikler-panel-pane-1 {

    .views-row-first {

      .node--os2web_base_news--os2sub_teaser_mini {

        .upper-part {

          a {
            font-size: 1.125em;
            padding: 15px;
          }
        }

        .text-contraster {
          height: 300px;
        }

        .bottom-part {

          & header {

            & h2 {
              font-size: 3.125em;
            }
          }
        }

        & .overlay {

          .overlay-content {
            padding-left: 65px;
            padding-right: 65px;

            .field-type-text-with-summary {
              font-size: 1.75em;
            }

            a {
              padding: 10px 15px;
              font-size: 2em;
              margin-top: 65px;
            }
          }
        }
      }
    }
  }
}

// Desktop - only
@media #{$breakpoint-desktop-only} {
}

// Large desktop - and up
@media #{$breakpoint-large-desktop-and-up} {
}

// Print
@media print {
}

// Touch
.touchevents {
}

// No touch
.no-touchevents {
}

// --------------------------------------------------
// Mixins
// --------------------------------------------------

// Social icons mixin
@mixin social-mixin-icons() {
}
